import { styled, TextField } from "@mui/material";

export const StyledTextField = styled(TextField)`
  border: 1px solid rgba(224, 226, 228, 1);
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);

  & input {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    color: #1f292e;
    padding: 12px 24px 12px 12px;

    &::placeholder {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      color: #a0a7ab;
      font-style: normal;
    }
    &:focus {
      border-color: rgba(10, 114, 195, 1);
    }
  }

  & fieldset {
    border: none;
  }
`;
