// -------------------------------------------------------------------------
// IMPORTS
// -------------------------------------------------------------------------
import { useContext, useState, useCallback, useMemo, forwardRef, useImperativeHandle } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Controller, useForm, Form } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";

import { Context } from "../../Store/Store";
import LoginService from "../../Services/LoginService";
import { SendAccountReactivationEmailService } from "../../Services/AccountActivation";

import { handleSuccessLogin } from "./Common/Functions";
import { commonStyles } from "./Common/CommonStyles";
import { StyledTextField } from "./Common/TextField";
import StyledButton from "../NurtureProspects/ReusableComponents/Button";
import { StyledLink } from "../NurtureProspects/ReusableComponents/StyledLink";

import { IoMdLock } from "react-icons/io";
import { FcGoogle } from "react-icons/fc";
import { MdErrorOutline } from "react-icons/md";
import { IoPersonCircleOutline } from "react-icons/io5";

import { Box, Button, CircularProgress, InputAdornment, IconButton, useTheme, useMediaQuery } from "@mui/material";
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from "@mui/icons-material";

// -------------------------------------------------------------------------
// STYLES
// -------------------------------------------------------------------------

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "48px",
    maxWidth: "100%",

    "& h1": {
      fontSize: "32px",
      fontWeight: "600",
      fontFamily: "Inter, sans-serif",
      color: "rgba(65, 80, 88, 1)",
      margin: "0",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",

    "& #googleAuth": {
      position: "relative",
      "& > button": {
        width: "100%",
        display: "flex !important",
        alignitems: "center !important",
        justifyContent: "center !important",
        gap: "12px !important",
        borderRaidus: "8px !important",
        fontFamily: "Inter !important",
        fontWeight: "500 !important",
        fontSize: "16px !important",
        padding: "12px 24px !important",
        color: "rgba(65, 80, 88, 1) !important",
        border: "1px solid rgba(224, 226, 228, 1) !important",
        textTransform: "none",
        lineHeight: "100%",
        transition: "all .3s",
        height: "48px",
        boxShadow: "none !important",
        margin: "0 !important",
        background: "rgba(255, 255, 255, 1) !important",

        "& > *": {
          margin: "0 !important",
          padding: "0 !important",
        },

        "&:hover": {
          background: "#fdfdfd !important",
        },

        "& svg": {
          height: "18px",
          width: "18px",
        },
      },
    },
  },
  separator: {
    position: "relative",
    textAlign: "center",
    "& span": {
      position: "relative",
      zIndex: "1",
      fontSize: "12px",
      fontWeight: "600",
      color: "rgba(65, 80, 88, 1)",
      paddingInline: "8px",
      background: "rgba(249, 251, 253, 1)",
    },
    "&::before": {
      content: '""',
      display: "inline-block",
      position: "absolute",
      top: "50%",
      left: "0",
      transform: "translateY(-50%)",
      height: "1px",
      width: "100%",
      background: "rgba(224, 226, 228, 1)",
    },
  },
  forgotPassword: {
    display: "flex",
    justifyContent: "flex-end",
    "& a": {
      fontSize: "14px !important",
      textDecoration: "underline !important",
    },
  },
  lastLogin: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    zIndex: "1",
    top: "-15px",
    right: "15px",
    padding: "4px 8px",
    borderRadius: "8px",
    border: "1px solid rgba(234, 171, 62, 1)",
    background: "rgba(254, 251, 245, 1)",
    height: "23px",

    "& span": {
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "14.5px",
      color: "rgba(234, 171, 62, 1)",
    },
  },
};

// -------------------------------------------------------------------------
// MAIN
// -------------------------------------------------------------------------

const LoginForm = forwardRef((props, ref) => {
  const theme = useTheme();
  const smallDisplay = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const navigate = useNavigate();
  const context = useContext(Context);
  const lastLogin = JSON.parse(localStorage.getItem("lastLogin"));

  const [isLoading, setIsLoading] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  // ---------------------------------------------------------------------------
  // REACT HOOK FORM
  // ---------------------------------------------------------------------------

  const formDefaultValues = useMemo(() => {
    return {
      email: lastLogin?.lastLogin === "email" ? lastLogin?.email : "",
      password: "",
    };
  }, [lastLogin]);

  const {
    control: formController,
    formState: { errors: formErrors },
    handleSubmit,
  } = useForm({
    defaultValues: formDefaultValues,
    values: formDefaultValues,
  });

  // ---------------------------------------------------------------------------
  // HANDLERS
  // ---------------------------------------------------------------------------
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useImperativeHandle(ref, () => ({
    submitForm: (withGoogle, data) => handleLogin(withGoogle, data),
  }));

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => handleLogin(true, tokenResponse.access_token),
  });

  const handleLogin = async (withGoogle = false, data) => {
    try {
      setSubmitResponse(null);
      setIsLoading(true);
      const { email, password } = data;
      const payload = withGoogle ? { accessToken: data } : { email, password };
      const response = await LoginService(payload);
      const json = await response.json();

      if (
        response.status === 200 &&
        (json.message === "external" || json.message === "payment stopped" || json.data.user.group === "Growth Plan")
      ) {
        handleSuccessLogin(json, context, withGoogle);

        if (location.state) {
          redirect(location.state.from.pathname);
        } else {
          redirect("/Home");
        }
      } else if (response.status === 200) {
        // Handle successful login for members
        handleSuccessLogin(json, context, withGoogle);

        if (location.state) {
          redirect(location.state.from.pathname);
        } else {
          redirect("/Dashboard");
        }
      } else if (response.status === 403) {
        if (json.message === "Account no longer active") {
          // Handle inactive user
          setSubmitResponse(
            "Your account is not active. Please click on the account activation link we just sent to your email."
          );
          SendAccountReactivationEmailService(email, password);
        } else {
          setSubmitResponse("Incorrect e-mail or password.");
        }
      } else if (response.status === 429) {
        setSubmitResponse("Too many requests. Please wait 1 minute.");
      } else {
        setSubmitResponse("Something went wrong. Please contact support.");
      }
    } catch (err) {
      setSubmitResponse("Something went wrong. Please contact support.");
    } finally {
      setIsLoading(false);
    }
  };

  const redirect = useCallback(
    (path) => {
      navigate(path);
    },
    [navigate]
  );

  // ---------------------------------------------------------------------------
  // COMPONENT RENDERING
  // ---------------------------------------------------------------------------

  return (
    <Box sx={styles.container}>
      <h1>Welcome back !</h1>
      <Form control={formController} onSubmit={handleSubmit((data) => handleLogin(false, data))}>
        <Box sx={{...styles.form, minWidth: smallDisplay ? "auto" : "430px"}}>
          <Box id="googleAuth">
            {!!lastLogin && lastLogin?.lastLogin === "auth0" && (
              <Box sx={styles.lastLogin}>
                <span>👇 Last login (with {lastLogin?.email})</span>
              </Box>
            )}
            <Button onClick={() => handleGoogleLogin()}>
              <FcGoogle />
              <span>Continue with Google</span>
            </Button>
          </Box>
          <Box sx={styles.separator}>
            <span>Or login with e-mail</span>
          </Box>
          {!!submitResponse && (
            <Box sx={{ ...commonStyles.globalError, maxWidth: "430px" }}>
              <MdErrorOutline />
              <span>{submitResponse}</span>
            </Box>
          )}

          <Box sx={{ position: "relative" }} display="flex" flexDirection="column" gap="8px">
            {!!lastLogin && lastLogin?.lastLogin === "email" && (
              <Box sx={styles.lastLogin}>
                <span>👇 Last login</span>
              </Box>
            )}
            <Controller
              name="email"
              control={formController}
              rules={{
                required: "required.",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Invalid email address",
                },
              }}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  variant="outlined"
                  placeholder="Email"
                  error={!!formErrors?.email}
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IoPersonCircleOutline
                          style={{ width: "24px", height: "24px", color: "rgba(65, 80, 88, 1) " }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap="8px">
            <Controller
              name="password"
              control={formController}
              rules={{
                required: "required.",
              }}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  variant="outlined"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="new-password"
                  error={!!formErrors?.password}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IoMdLock style={{ width: "24px", height: "24px", color: "rgba(65, 80, 88, 1) " }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          // onMouseDown={(ev) => {ev.preventDefault()}}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Box sx={styles.forgotPassword}>
              <StyledLink to="/ForgotPassword">Forgot your password ?</StyledLink>
            </Box>
          </Box>
          <Box sx={commonStyles.actions}>
            <StyledButton
              disabled={isLoading}
              sx={{ "& span": { color: "white !important", width: "24px !important", height: "24px !important" } }}
            >
              {isLoading ? <CircularProgress /> : "Login"}
            </StyledButton>
            <span>
              New to Engage AI ? <StyledLink to="/onboarding/CreateAccount">Create account</StyledLink>
            </span>
          </Box>
        </Box>
      </Form>
    </Box>
  );
});

export default LoginForm;
