import { styled } from "@mui/material";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)(() => ({
  "&, &:link, &:visited, &:active, &:hover ": {
    fontSize: "16px",
    fontWeight: "600",
    textDecoration: "none",
    color: "rgba(10, 114, 195, 1) !important",
  },
}));
