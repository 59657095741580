// ------------------------------------------------------------------------------------
// IMPORTS
// ------------------------------------------------------------------------------------
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useOutletContext, useSearchParams } from "react-router-dom";
import { formateNumber } from "../../../../Utils/reusableFunctions";
import { useNurtureContext } from "../../../../Context/NurtureContext";
import toast from "react-hot-toast";
import {
  DeleteMultipleProspectsService,
  DeleteProspectService,
  EditProspectsService,
} from "../../../../Services/GetProspectsPosts";

import CustomisedTable from "../../ReusableComponents/CustomisedTable";
import TableFilter from "../../ReusableComponents/TableFilter";
import CustomisedAvatar from "../../ReusableComponents/CustomisedAvatar";
import Pagination from "../../ReusableComponents/Pagination";
import Copilot from "./Copilot";
import { StyledSwitch } from "../../ReusableComponents/StyledSwitch";
import Modal from "../../ReusableComponents/Modal";
import UpgradeModal from "./UpgradeModal";
import SucessModal from "./SucessModal";
import StyledSpinner from "../../ReusableComponents/StyledSpinner";
import StyledButton from "../../ReusableComponents/Button";

import { Box, IconButton, MenuItem, Paper, Select, TextField, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { IoMdInformationCircle } from "react-icons/io";
import { FaPlus } from "react-icons/fa6";
import { BiExport } from "react-icons/bi";
import SucessAddProspect from "./SuccessAddProspect";
import MaxLimitDialog from "./MaxLimitDialog";
import AddUpdateProspect from "./AddUpdateProspect";
import { FaLinkedin } from "react-icons/fa";
import ImportLeadsDialog from "./importProspects/ImportLeadsDialog";
import { mixpanelCTAEvent, mixpanelEvent  } from "../../../../Utils/mixpanelUtils";

// ------------------------------------------------------------------------------------
// STYLES CUSTOMIZATION
// ------------------------------------------------------------------------------------

const styles = {
  Paper: {
    background: "#fff",
    padding: "5px",
    border: "1px solid #D1D9E2",
    borderRadius: "10px",
    width: "100%",
  },
  Box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "11px 0 8px 0",
    width: "100%",
    position: "relative",
    "& .paginationBtns": {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
    },
  },
  btn: {
    height: "40px",
    width: "130px",
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    paddingBlock: "8px",
    fontSize: "13px",
    fontWeight: "600",
    alignSelf: "end",
  },
  filterText: {
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: "700",
    lineHeight: "20px",
    color: "#2F3EAC",
  },
  footerText: {
    marginRight: "auto",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: "600",
    lineHeight: "20px",
    color: "#2F3EAC",
  },
  cellProps: {
    fontSize: "12px",
    fontWeight: "500",
    color: "#1E1E1E",
  },
  FilterInput: {
    backgroundColor: "#F0F1F3",
    borderRadius: "100px",
    padding: "4px 15px !important",
    maxWidth: "140px",
    fontSize: "12px",
    fontWeight: "600",
    color: "#7C8B9D99",
    "&::placeholder": {
      fontSize: "12px",
      fontWeight: "600",
      color: "#7C8B9D99",
      opacity: 1,
    },
  },
  FilterSelect: {
    backgroundColor: "#F0F1F3",
    borderRadius: "100px",
    padding: "4px 15px !important",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "20px",
    color: "#7C8B9D99",
    "& .MuiSelect-select:focus": {
      background: "none",
    },
    "& svg": {
      height: "24px",
      width: "24px",
      color: "#7C8B9D99",
      marginRight: "4px",
    },
  },
  inactive: {
    border: "1px solid #FF8A00",
    color: "#FF8A00",
    padding: "8px 16px",
    borderRadius: "24px",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "14px",
  },
  active: {
    border: "1px solid var(--green)",
    color: "var(--green)",
    padding: "8px 16px",
    borderRadius: "24px",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "14px",
  },
};

// ------------------------------------------------------------------------------------
// MAIN
// ------------------------------------------------------------------------------------

const postingStatusTooltip =
  "We show you if the prospect has been actively creating content over the last 30 days on Linkedin.";
const touchpointsTooltip = "The number of engagements made with a prospect using Engage AI.";
const MAX_LIMIT_ADD_PROSPECTS = 10000;

let initAutoSelect = true;

function Prospects() {
  const theme = useTheme();
  const bigScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const { userGroup, premiumUser, prospectsLimit } = useOutletContext();
  const {
    prospects,
    isLoadingProspects: isLoading,
    setIsLoadingProspects: setIsLoading,
    setRefreshProspectsKey,
    setRefreshPostsKey,
    lists = [],
    setRefreshListsKey,
  } = useNurtureContext();

  const [searchParams, setSearchParams] = useSearchParams();

  // ------------------------------------------------------------------------------------
  // STATE
  // ------------------------------------------------------------------------------------
  const [selectedList, setSelectedList] = useState(0);
  const [prospectName, setProspectName] = useState("");
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [currentPageProspects, setCurrentPageProspects] = useState([]);
  const [filteredProspects, setFilteredProspects] = useState(prospects);
  const [allProspects, setAllProspects] = useState(prospects || []);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [showUpgradeRequired, setShowUpgradeRequired] = useState(false);
  const currentPage = !searchParams.get("page") ? 1 : parseInt(searchParams.get("page"));
  const [autoSelectLoading, setAutoSelectLoading] = useState(false);
  const [showSuccessAutoSelect, setShowSuccessAutoSelect] = useState(false);
  const [openAddUpdateProspect, setOpenAddUpdateProspect] = useState(false);
  const [dialogMode, setDialogMode] = useState(null);
  const [openMaxLimitDialog, setOpenMaxLimitDialog] = useState(false);
  const [prospect, setProspect] = useState({ success: false });
  const [openImportLeadsDialog, setOpenImportLeadsDialog] = useState(false);

  const lastAutoSelect = useRef();

  const monitoredProspects = useMemo(() => allProspects.filter((prospect) => prospect.is_monitoring), [allProspects]);
  const nonMonitoredProspects = useMemo(
    () => allProspects.filter((prospect) => !prospect.is_monitoring),
    [allProspects]
  );

  const inactives = monitoredProspects.filter((prospect) => prospect.is_active === false);
  const isAvailablesUpLimit = nonMonitoredProspects.length > prospectsLimit - monitoredProspects.length;

  // ------------------------------------------------------------------------------------
  // SUGGESTIONS LOGIC
  // ------------------------------------------------------------------------------------
  const autoSelectNumber = useMemo(() => {
    if (!initAutoSelect) return lastAutoSelect.current;
    if (isAvailablesUpLimit) {
      lastAutoSelect.current = prospectsLimit - monitoredProspects.length;
      return prospectsLimit - monitoredProspects.length;
    }
    lastAutoSelect.current = nonMonitoredProspects.length;
    return nonMonitoredProspects.length;
  }, [isAvailablesUpLimit, monitoredProspects.length, nonMonitoredProspects.length, prospectsLimit]);

  // ------------------------------------------------------------------------------------
  // HANDLERS
  // ------------------------------------------------------------------------------------

  const handleChange = (event) => {
    setSelectedList(event.target.value);
  };

  const handleDeleteProspect = async (id) => {
    try {
      const res = await DeleteProspectService(id);
      if (res.status === 200 || res.status === 500) {
        setRefreshProspectsKey((oldKey) => oldKey + 1);
        setRefreshPostsKey((oldKey) => oldKey + 1);
        setRefreshListsKey((oldKey) => oldKey + 1);
        setRowsSelected([]);
        toast.success("Prospect deleted successfully");
      }
    } catch (err) {
      toast.error("Failed to delete prospect");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAutoSelect = async (numProspects) => {
    try {
      initAutoSelect = false;
      setAutoSelectLoading(true);
      const payload = nonMonitoredProspects
        .slice(0, numProspects)
        .map(({ "LinkedIn profile": linkedin, "last name": lastName, "first name": firstName, headline, id }) => ({
          "LinkedIn profile": linkedin,
          "first name": firstName,
          "last name": lastName,
          id,
          headline,
          monitoring: true,
        }));
      await EditProspectsService({ data: payload });
      setRefreshProspectsKey((key) => key + 1);
      setShowSuccessAutoSelect(true);
    } catch (err) {
      toast.error("Failed to auto select prospects");
    } finally {
      setTimeout(() => {
        setAutoSelectLoading(false);
      }, 1000);
    }
  };

  const handleDeleteMultipleProspects = async (data = [], selectedRows) => {
    try {
      let payload;
      if (data && data.length > 0) {
        payload = data;
      } else {
        const rowsIds = selectedRows.data.map((row) => row.dataIndex);
        const selectedprospectsIds = rowsIds.map((_id) => currentPageProspects[_id].id);
        payload = selectedprospectsIds;
      }
      const res = await DeleteMultipleProspectsService(payload);
      if (res.status === 200 || res.status === 500) {
        setRefreshProspectsKey((oldKey) => oldKey + 1);
        setRefreshPostsKey((oldKey) => oldKey + 1);
        setRefreshListsKey((oldKey) => oldKey + 1);
        setRowsSelected([]);
        if (!data || data.length === 0) {
          toast.success("Prospects deleted successfully");
        }
      }
    } catch (err) {
      toast.error("Failed to delete the prospects");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSwitchMonitoring = async (payload) => {
    if (monitoredProspects.length >= prospectsLimit && payload.monitoring === true) return setShowUpgradeRequired(true);
    const draft = [...allProspects];
    const index = draft.findIndex((prospect) => prospect.id === payload.id);
    draft[index].is_monitoring = payload.monitoring;
    setAllProspects(draft);
    await EditProspectsService({ data: [payload] });
    if (payload.monitoring) {
      toast.success(
        <span>
          <strong>
            {payload["first name"]} {payload["last name"]}
          </strong>{" "}
          updates will be retrieved in the next monitoring session.
        </span>
      );
    }
  };

  // ------------------------------------------------------------------------------------

  useEffect(() => {
    if (prospects && prospects.length > 0) {
      setAllProspects(prospects);
      setFilteredProspects(prospects);
    }
  }, [prospects]);

  useEffect(() => {
    const from = (currentPage - 1) * resultsPerPage;
    const to = from + resultsPerPage - 1;
    setCurrentPageProspects(filteredProspects.slice(from, to + 1));
  }, [filteredProspects, currentPage, resultsPerPage]);

  const filterTable = useCallback(() => {
    let draft = allProspects;
    if (selectedList && selectedList !== 0) {
      const listId = lists.find((list) => list.title === selectedList)?.id;
      draft = draft.filter((prospect) => prospect["list id"] === listId);
    }
    if (prospectName) {
      draft = draft.filter((prospect) => prospect.name.toLowerCase().includes(prospectName.toLowerCase()));
    }
    setFilteredProspects(draft);
  }, [selectedList, prospectName, allProspects, lists]);

  useEffect(() => {
    filterTable();
  }, [filterTable, selectedList, prospectName]);

  useEffect(() => {
    searchParams.set("page", 1);
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList, prospectName, resultsPerPage]);

  const monitorTooltip = useMemo(() => {
    if (userGroup === "Engage AI") {
      return (
        <>
          You can add up to {formateNumber(prospectsLimit)} prospects for monitoring.
          <strong> Upgrade to a paid plan for more capacity.</strong>
        </>
      );
    }
    if (userGroup === "Member" || userGroup === "Premium")
      return <>{`You can add up to ${formateNumber(prospectsLimit)} prospects for monitoring.`}</>;
    return (
      <>
        You can add up to {formateNumber(prospectsLimit)} prospects for monitoring.
        <strong> Upgrade to a higher plan for more capacity.</strong>
      </>
    );
  }, [userGroup, prospectsLimit]);

  // --------------------------------------------------------------------------------------
  // TABLE
  // --------------------------------------------------------------------------------------

  const tableOptions = {
    search: false,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    selectableRowsOnClick: false,
    selectableRowsHideCheckboxes: false,
    rowsSelected,
    rowsPerPage: resultsPerPage,
    textLabels: {
      body: {
        noMatch: `You don't have any prospect yet. Click on "New Prospect" to add one.`,
      },
    },
    customToolbarSelect: (selectedRows) => {
      return (
        <IconButton
          className="deleteAll"
          onClick={() => handleDeleteMultipleProspects([], selectedRows)}
          sx={{ marginRight: "20px" }}
        >
          <img style={{ width: "18px", height: "18px" }} src="/images/deleteIcon.svg" alt="delete icon" />
        </IconButton>
      );
    },
    customFooter: () => (
      <tfoot>
        <tr>
          <td
            style={{
              display: "flex",
              alignItems: "center",
              height: "auto",
              paddingInline: "20px",
              paddingBlock: "15px",
            }}
          >
            {premiumUser ? (
              <Box sx={styles.Box}>
                <Pagination
                  ressource="Prospects"
                  resulstsChoices={[10, 15, 50, 100]}
                  resultsPerPage={resultsPerPage}
                  setResultsPerPage={setResultsPerPage}
                  count={filteredProspects?.length}
                />
              </Box>
            ) : (
              <Box sx={{ ...styles.Box, flexDirection: "column", gap: "32px" }}>
                <Pagination
                  ressource="Prospects"
                  resulstsChoices={[10, 15, 50, 100]}
                  resultsPerPage={resultsPerPage}
                  setResultsPerPage={setResultsPerPage}
                  count={filteredProspects?.length}
                />
              </Box>
            )}
          </td>
        </tr>
      </tfoot>
    ),
  };

  const tableColumns = [
    {
      name: "name",
      label: "Name",
      options: {
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            width: "20%",
          },
        }),
        setCellProps: () => ({
          style: { ...styles.cellProps },
        }),
        customBodyRenderLite: (dataIndex) => {
          const { name, profile_photo_url, headline } = currentPageProspects[dataIndex] ?? {};
          return <CustomisedAvatar tableMode={true} imageUrl={profile_photo_url} name={name} headline={headline} />;
        },
      },
    },
    {
      name: "LinkedIn profile",
      label: "LinkedIn",
      options: {
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            width: "10%",
          },
        }),
        customBodyRenderLite: (dataIndex) => {
          const { "LinkedIn profile": linkedinProfile } = currentPageProspects[dataIndex] ?? {};
          return (
            <Link to={linkedinProfile} target="_blank" rel="noopener noreferrer">
              <StyledButton
                size="small"
                variation="roundedPrimary"
                sx={{
                  textTransform: "none",
                  gap: "4px",
                }}
              >
                <FaLinkedin style={{ width: "14px", height: "14px" }} alt="linkedin icon" />
                <span>Visit</span>
              </StyledButton>
            </Link>
          );
        },
      },
    },
    {
      name: "list id",
      label: "Lists",
      options: {
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            width: "15%",
          },
        }),
        customBodyRenderLite: (dataIndex) => {
          const { "list id": listId } = currentPageProspects[dataIndex] ?? {};
          const list = lists?.find((list) => list.id === listId)?.title;
          if (!list) return null;
          return (
            <Box
              sx={{
                background: "#F0F1F3",
                padding: "4px 12px",
                fontSize: "12px",
                fontWeight: "600",
                lineHeight: "20px",
                color: "#7c8b9d",
                textTransform: "capitalize",
                borderRadius: "100px",
                textAlign: "center",
              }}
            >
              <span>{list}</span>
            </Box>
          );
        },
      },
    },
    {
      name: "engagement count",
      label: "Touchpoints",
      options: {
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            width: "15%",
          },
        }),
        customHeadLabelRender: () => (
          <Box
            sx={{ width: "max-content", "& > *": { minWidth: "fit-content", flex: 1 } }}
            display="flex"
            alignItems="center"
            gap="6px"
          >
            <span>Touchpoints</span>
            <Tooltip title={touchpointsTooltip} placement="bottom-end">
              <span>
                <IoMdInformationCircle style={{ height: "16px", width: "16px" }} />
              </span>
            </Tooltip>
          </Box>
        ),
      },
    },
    {
      name: "",
      label: `Monitoring ${monitoredProspects.length}/${formateNumber(prospectsLimit)}`,
      options: {
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            width: "20%",
          },
        }),
        customHeadLabelRender: () => (
          <Box
            sx={{ width: "max-content", "& > *": { minWidth: "fit-content", flex: 1 } }}
            display="flex"
            alignItems="center"
            gap="6px"
          >
            <span>
              Monitoring {monitoredProspects.length}/{formateNumber(prospectsLimit)}
            </span>
            <Tooltip title={monitorTooltip} placement="bottom-end">
              <span>
                <IoMdInformationCircle style={{ height: "16px", width: "16px" }} />
              </span>
            </Tooltip>
          </Box>
        ),
        customBodyRenderLite: (dataIndex) => {
          const monitoring = currentPageProspects[dataIndex]?.is_monitoring;
          const lastName = currentPageProspects[dataIndex]["last name"];
          const firstName = currentPageProspects[dataIndex]["first name"];
          const linkedin = currentPageProspects[dataIndex]["LinkedIn profile"];
          const { id, headline } = currentPageProspects[dataIndex];

          const payload = {
            "last name": lastName,
            "first name": firstName,
            "LinkedIn profile": linkedin,
            headline,
            id,
          };

          return (
            <>
              {(userGroup === "Member" || userGroup === "Premium") &&
              monitoredProspects.length >= prospectsLimit &&
              !monitoring ? (
                <Tooltip
                  title={`Currently, you can't monitor more than ${formateNumber(prospectsLimit)} prospects`}
                  placement="bottom-end"
                >
                  <span>
                    <StyledSwitch checked={monitoring} disabled />
                  </span>
                </Tooltip>
              ) : (
                <StyledSwitch
                  checked={monitoring}
                    onChange={(ev) => {
                      const { name, "LinkedIn profile": linkedInProfile } = currentPageProspects[dataIndex] ?? {};
                      mixpanelEvent("monintoring_action", {
                        status: ev.target.checked ? "enabled" : "disabled",
                        name: name,
                        linkedin_url: linkedInProfile,
                        screen_name: "monitored prospects and their posting activities",
                      });
                      handleSwitchMonitoring({
                        ...payload,
                        monitoring: ev.target.checked,
                      });
                  }}
                />
              )}
            </>
          );
        },
      },
    },
    {
      name: "",
      label: "Posting Status",
      options: {
        sort: false,
        customHeadLabelRender: () => (
          <Box
            sx={{ width: "max-content", "& > *": { minWidth: "fit-content", flex: 1 } }}
            display="flex"
            alignItems="center"
            gap="6px"
          >
            <span>Posting Status</span>
            <Tooltip title={postingStatusTooltip} placement="bottom-end">
              <span>
                <IoMdInformationCircle style={{ height: "16px", width: "16px" }} />
              </span>
            </Tooltip>
          </Box>
        ),
        customBodyRenderLite: (dataIndex) => {
          const { is_active } = currentPageProspects[dataIndex] ?? {};
          if (is_active === null) return null;
          return <span style={is_active ? styles.active : styles.inactive}>{is_active ? "Active" : "Inactive"}</span>;
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const { id } = currentPageProspects[dataIndex] ?? {};
          return (
            <Box display="flex" alignItems="center" justifyContent="center">
              <IconButton
                onClick={() => {
                  setRowsSelected([{ dataIndex }]);
                  setDialogMode("edit");
                  setOpenAddUpdateProspect(true);
                }}
              >
                <img style={{ width: "18px", height: "18px" }} src="/images/editTone.svg" alt="edit icon" />
              </IconButton>
              <IconButton onClick={(e) => handleDeleteProspect(id)}>
                <RiDeleteBin6Fill style={{ width: "18px", height: "18px", color: "var(--color-red)" }} />
              </IconButton>
            </Box>
          );
        },
      },
    },
  ];

  const table = {
    columns: tableColumns,
    options: tableOptions,
    data: currentPageProspects || [],
  };

  // --------------------------------------------------------------------------------------
  // COMPONENT RENDERING
  // --------------------------------------------------------------------------------------

  return (
    <>
      <Box sx={{ position: "relative" }} display="flex" flexDirection="column" gap="2rem">
        <StyledSpinner isLoading={isLoading || autoSelectLoading} />
        {((inactives && !!inactives.length) ||
          (monitoredProspects.length < prospectsLimit && nonMonitoredProspects.length > 0)) && (
          <Copilot
            inactives={inactives}
            monitored={monitoredProspects}
            nonMonitoredProspects={nonMonitoredProspects}
            prospectsLimit={prospectsLimit}
            onAutoSelect={handleAutoSelect}
            onDelete={handleDeleteMultipleProspects}
            isAvailablesUpLimit={isAvailablesUpLimit}
            showSuccessAutoSelect={showSuccessAutoSelect}
            setShowSuccessAutoSelect={setShowSuccessAutoSelect}
            autoSelectNumber={autoSelectNumber}
            initAutoSelect={initAutoSelect}
          />
        )}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <TableFilter
            style={{ visibility: allProspects && allProspects.length > 0 ? "visible" : "hidden" }}
            premiumUser={premiumUser}
          >
            <TextField
              placeholder="Prospect Name"
              variant="standard"
              value={prospectName || ""}
              onChange={(e) => {
                setProspectName(e.target.value);
              }}
              InputProps={{
                disableUnderline: true,
                style: styles.FilterInput,
              }}
            />

            <Select
              displayEmpty
              variant="standard"
              value={selectedList || ""}
              onChange={handleChange}
              sx={styles.FilterSelect}
              disableUnderline={true}
              IconComponent={ExpandMoreIcon}
            >
              <MenuItem key={0} value="">
                Choose a list
              </MenuItem>
              {lists?.length &&
                lists.map((list) => {
                  return (
                    <MenuItem key={list.id} value={list.title}>
                      {list.title}
                    </MenuItem>
                  );
                })}
            </Select>
          </TableFilter>
          <Box display="flex" justifyContent="flex-end" gap="8px">
            <StyledButton
              onClick={() => {
                if (allProspects?.length >= MAX_LIMIT_ADD_PROSPECTS) {
                  setOpenMaxLimitDialog(true);
                } else {
                  setOpenImportLeadsDialog(true);
                }
              }}
              variation="secondary"
              sx={{
                "&": {
                  color: "var(--color-brand)",
                  background: " #fff",
                  border: "1px solid var(--color-brand)",
                },

                "&:hover": {
                  color: " #fff",
                  background: "var(--color-brand)",
                },
              }}
            >
              <BiExport style={{ height: "16px", width: "16px" }} />
              <span>Import leads</span>
            </StyledButton>

            <Modal>
              <StyledButton
                onClick={() => {
                  mixpanelCTAEvent({
                    buttonName: "Add prospect",
                    ctaPosition: "right-side",
                    screenName: 'save prospects via web app for monitoring', 
                  });
                  if (allProspects?.length >= MAX_LIMIT_ADD_PROSPECTS) {
                    setOpenMaxLimitDialog(true);
                  } else {
                    setDialogMode("add");
                    setOpenAddUpdateProspect(true);
                  }
                }}
              >
                <FaPlus style={{ height: "16px", width: "16px" }} />
                <span>Add prospect</span>
              </StyledButton>

              <Modal.Window
                name="upgradeModal"
                shouldOpen={showUpgradeRequired}
                noPadding={true}
                onClose={() => setShowUpgradeRequired(false)}
              >
                <UpgradeModal />
              </Modal.Window>
              <Modal.Window
                name="auto-select"
                shouldOpen={showSuccessAutoSelect}
                closeIcon={true}
                modalStyles={{ width: `Max(${bigScreen ? "40%" : "30%"},400px)` }}
                onClose={() => {
                  setShowSuccessAutoSelect(false);
                  initAutoSelect = true;
                }}
              >
                <SucessModal
                  onClose={() => {
                    setShowSuccessAutoSelect(false);
                    initAutoSelect = true;
                  }}
                  title={`${formateNumber(autoSelectNumber)} prospects added to monitoring`}
                  text={
                    <>
                      During the next monitoring check, if any of your prospects are inactive, you will be{" "}
                      <strong>notified</strong> and can monitor other prospects in their place.
                    </>
                  }
                />
              </Modal.Window>
            </Modal>
          </Box>
        </Box>
        {!isLoading && (
          <Paper sx={styles.Paper}>
            <CustomisedTable table={table} />
          </Paper>
        )}
      </Box>
      {openAddUpdateProspect && dialogMode && (
        <AddUpdateProspect
          open={openAddUpdateProspect}
          onClose={() => setOpenAddUpdateProspect(false)}
          mode={dialogMode}
          monitoredProspects={monitoredProspects}
          setProspect={setProspect}
          prospectData={currentPageProspects[rowsSelected[0]?.dataIndex]}
        />
      )}

      {prospect.success && (
        <SucessAddProspect
          open={prospect.success}
          onClose={() => setProspect({ success: false })}
          prospect={prospect}
        />
      )}

      {openImportLeadsDialog && (
        <ImportLeadsDialog
          open={openImportLeadsDialog}
          onClose={() => setOpenImportLeadsDialog(false)}
          monioredProspects={monitoredProspects}
        />
      )}

      {openMaxLimitDialog && <MaxLimitDialog open={openMaxLimitDialog} onClose={() => setOpenMaxLimitDialog(false)} />}
    </>
  );
}

export default Prospects;
