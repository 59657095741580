// ----------------------------------------------------------------------------
// IMPORTS
// ----------------------------------------------------------------------------
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { isTokenValid } from "../../Services/AuthService";
import LoadTestimonialsService from "../../Services/LoadTestimonials";
import AccountActivationService from "../../Services/AccountActivation";

import LoginForm from "./LoginForm";
import CreateAccount from "./CreateAccount";
import ForgotPassword from "./ForgotPassword";
import TestimonialsSlider from ".//TestimonialsSlider";

import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";

// ----------------------------------------------------------------------------
// STYLES
// ----------------------------------------------------------------------------

const styles = {
  page: {
    minHeight: "100dvh",
    display: "flex",
    height: "100%",
    width: "100%",

    background: "rgba(249, 251, 253, 1)",
    position: "relative",
  },
  leftContainer: {
    background: "linear-gradient(180deg, rgba(10, 114, 195, 0.85) 0%, #0A72C3 100%), url(/images/loginBg.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100%",
    width: "100%",
    maxWidth: "inherit !important",
    position: "fixed",
    left: "0",
    top: "0",
    bottom: "0",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "48px",
    color: "#fff",

    "& > img": {
      height: "32px",
    },

    "& > h2": {
      fontSize: "26px",
      fontWeight: "600",
      lineHeight: "40px",
    },
  },
  right: {
    marginBlock: "auto",
    paddingBlock: "32px",
  }
};

// ----------------------------------------------------------------------------
// MAIN
// ----------------------------------------------------------------------------

const LoginSignupBase = (props) => {
  const { pathname, message, action } = props;

  const theme = useTheme();
  const navigate = useNavigate();
  const smallDisplay = useMediaQuery(theme.breakpoints.down("md"));

  const loginFormRef = useRef();
  const [testimonials, setTestimonials] = useState({});

  const d = new Date();
  d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("token");
  const uidb64 = urlParams.get("uid");
  const pwb64 = urlParams.get("secret");

  if (token && uidb64 && pwb64) {
    AccountActivationService(token, uidb64, pwb64).then((res) => {
      res.json().then((json) => {
        if (res.status === 200) {
          const email = json.data.email;
          const pw = json.data.pw;

          if (loginFormRef.current) {
            loginFormRef.current.submitForm(false, {
              email: email,
              password: pw,
            });
          }
        }
      });
    });
  }

  // ---------------------------------------------------------------------------
  // HANDLERS
  // ---------------------------------------------------------------------------

  const actionToPage = (action) => {
    switch (action) {
      case "login":
        return <LoginForm ref={loginFormRef} />;
      case "signup":
        return <CreateAccount />;
      case "forgotPassword":
        return <ForgotPassword action="reset" />;
      case "changePassword":
        return <ForgotPassword action="update" />;
      default:
        return <LoginForm />;
    }
  };

  // --------------------------------------------------------------------------------------------

  useEffect(() => {
    if (window.self !== window.top) {
      // The page is embedded in an iframe
      // Navigate to top window
      window.top.location = window.self.location;
    }
    const authed = isTokenValid();
    const t = localStorage.getItem("user");
    const u = JSON.parse(t);
    const token = localStorage.getItem("token");
    if (u) {
      //const group = u.group;
      document.cookie = "fp_email=" + u.email + ";" + expires + "; path=/";
      document.cookie = "fp_user_type=" + u.group + ";" + expires + "; path=/";
      document.cookie = "fp_user_trial_end_date=" + u.trial_end_date + ";" + expires + "; path=/";
      document.cookie = "eai_token=" + token + ";" + expires + "; path=/";
      if (authed && authed.status && action !== "changePassword") {
        navigate("/Home");
      }
    }

    return () => {};
  }, [navigate, message, pathname, action, expires, token, uidb64]);

  useEffect(() => {
    LoadTestimonialsService()
      .then((res) => {
        res.json().then((json) => {
          setTestimonials(json);
        });
      })
  }, []);

  // ---------------------------------------------------------------------------
  // COMPONENT RENDERING
  // ----------------------------------------------------------------------------

  return (
    <Box sx={styles.page}>
      <Grid container>
        {!smallDisplay && <Grid item md={5} xs={12}>
          <Box sx={{ ...styles.leftContainer, position: !smallDisplay ? "fixed" : "static" }}>
            <img
              style={{ marginBottom: smallDisplay ? "32px" : "80px" }}
              src="/images/engageAiWhiteLogo.svg"
              alt="Engage AI logo"
            />
            <h2
              style={{
                marginBottom: smallDisplay ? "48px" : "auto",
              }}
            >
              Enhance your experience <br /> with AI-driven solutions.
            </h2>
            <TestimonialsSlider testimonials={testimonials} />
          </Box>
        </Grid>}
        <Grid item md={7} xs={12} sx={styles.right}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingInline: smallDisplay && action === "signup" ? "32px" : "0px",
            }}
          >
            {actionToPage(action)}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginSignupBase;
