import mixpanel from 'mixpanel-browser';
import React from 'react';
import styles from './Onboarding.module.css';
import Topbar from './Topbar';

mixpanel.init("fce1521d323a955b4738b2588097761a", { debug: true, persistence: 'localStorage', ignore_dnt: true, });

const Ecosystem = () => {
    const trackEvent = () => {
        mixpanel.track(
            "Onboarding",
            {
                "origin": "Ecosystem",
                "navigate": "Engage with People who Matter"
            }
        );
    }
    return (
        <div >
        <div >
            <Topbar/>
            <div className={`${styles['main']} ${styles['center-align']}`}>
                <h3 style={{color:"#2F3EAC"}}>Available in Various Platforms</h3>
                <div className={`${styles['frow']} `} style={{justifyContent:"normal"}}>
                    <div style={{width:"100%"}}><iframe width="800" height="500" src="https://www.youtube.com/embed/I6h26Ul8ZZ0?si=oNF1whF3b766pdGX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
                </div>
                <div style={{width:"max-content", padding:"12px 16px", color:"white", background:"#3947A8", borderRadius:"8px", margin:"auto"}} >
                    <a href="/Onboarding/EngageAI/EngageWithPeopleWhoMatter" style={{color:"white", textDecoration:"none"}} onClick={trackEvent}>Next <img src='/images/right_arrow_white.svg' alt=''></img></a>
                </div>
            </div>
        </div>
        </div>
    );
}
export default Ecosystem;