import LaunchIcon from "@mui/icons-material/Launch";

import { Box } from "@mui/material";

import StyledButton from "../../ReusableComponents/Button";

import { MdDiamond } from "react-icons/md";
import { Link } from "react-router-dom";
import { mixpanelCTAEvent, mixpanelEvent } from "../../../../Utils/mixpanelUtils";

const styles = {
  heading: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "21px",
    color: "#415058",
    marginTop: "40px",
    marginBottom: "16px",
  },
  paragraphe: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "26px",
    color: "#415058",
    maxWidth: "450px",
  },
  ctaBox: {
    display: "flex",
    alignItems: "center",
    marginTop: "40px",
    background: "linear-gradient(to bottom, #FFB7431A 10%, #FFB74300 100%)",
    padding: "24px",
    border: "1px solid #E0E2E4",
    borderRadius: "4px",
    gap: "80px",
    textAlign: "left",

    "& h3": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "24px",
      color: "#1F292E",
      margin: "0",
    },
  },
  ctaList: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    listStyleImage: "url('/images/checkmark.png')",
    padding: "0",
    paddingLeft: "20px",
    margin: "0",

    "& li": {
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "22px",
      color: "#415058",
    },
  },
};

function NoPosts({ premiumUser, onCheckUpdates }) {
  return (
    <Box
      sx={{
        width: "100%",
        marginBlock: "5rem",
        textAlign: "center",
      }}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <img src="/images/noPostsImg.png" alt="No posts" />
      <h3 style={styles.heading}>No posts found</h3>
      <p style={styles.paragraphe}>
        {premiumUser ? (
          <>
            No posts are saved here. To check for new posts from your prospects, please &nbsp;
            <strong style={{ color: "#2F3EAC", cursor: "pointer" }}>
              <span onClick={onCheckUpdates}>check updates</span>
            </strong>
          </>
        ) : (
          <>
            No posts are saved here. For free accounts, an automatic check is executed <strong>weekly</strong> to
            collect recent posts from your prospects.
          </>
        )}
      </p>
      {!premiumUser && (
        <Box sx={styles.ctaBox}>
          <Box display="flex" flexDirection="column" flexGrow={1} gap="24px">
            <h3>Ready to maximize your strategy?</h3>
            <ul style={styles.ctaList}>
              <li>
                <strong>Never miss a post</strong> with <strong> daily</strong> automatic and <strong>anytime</strong>{" "}
                updates,
              </li>
              <li>
                <strong>Monitor more leads</strong> with bulk import or CRM integration,
              </li>
              <li>
                Unlock more additional advanced features.&nbsp;
                <a
                  href="https://bit.ly/3xdvInj"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontWeight: "600",
                    color: "var(--color-brand)",
                  }}
                  onClick={() => {
                    mixpanelEvent("section_expanded", {
                      is_readmore: true,
                      section_title: "Unlock more additional advanced features",
                      screen_name: "No posts found",
                    });
                  }}
                >
                  <span>Learn more</span>
                  <LaunchIcon style={{ width: "15px", height: "15px", marginLeft: "4px" }} />
                </a>
              </li>
            </ul>
          </Box>
          <Link
            style={styles.link}
            to="https://bit.ly/3xdvInj"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              mixpanelCTAEvent({
                buttonName: "Upgrade Now",
                ctaPosition: "hero",
                screenName: "No posts found",
              });
            }}
          >
            <StyledButton variation="warning">
              <MdDiamond style={{ height: "24px", width: "24px" }} />
              <span>Upgrade now</span>
            </StyledButton>
          </Link>
        </Box>
      )}
    </Box>
  );
}

export default NoPosts;
