export const getUserProperties = () => {
  return new Promise((resolve, reject) => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (!user) {
      resolve({
        email: null,
        currentPlan: null,
      });
    } else {
      const { email, group } = user;
      resolve({
        email,
        currentPlan: group,
      });
    }
  });
};