// ----------------------------------------------------------------------------
// IMPORTS
// ----------------------------------------------------------------------------
import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Controller, useForm, Form } from "react-hook-form";

import StyledButton from "../NurtureProspects/ReusableComponents/Button";

import { Box, CircularProgress, FormLabel, styled, TextField, useTheme, useMediaQuery } from "@mui/material";
import ForgotPasswordService from "../../Services/ForgotPasswordService";
import { commonStyles } from "./Common/CommonStyles";

import { MdErrorOutline } from "react-icons/md";

// ----------------------------------------------------------------------------
// STYLES
// ----------------------------------------------------------------------------

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "48px",

    "& h1": {
      fontSize: "32px",
      fontWeight: "600",
      fontFamily: "Inter, sans-serif",
      color: "rgba(65, 80, 88, 1)",
      margin: "0",
    },

    "& span": {
      maxWidth: "400px",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",

    "& #googleAuth": {
      position: "relative",
      "& > button": {
        width: "100%",
        display: "flex !important",
        alignitems: "center !important",
        justifyContent: "center !important",
        gap: "12px !important",
        borderRaidus: "8px !important",
        fontFamily: "Inter !important",
        fontWeight: "500 !important",
        fontSize: "16px !important",
        padding: "12px 24px !important",
        color: "rgba(65, 80, 88, 1) !important",
        border: "1px solid rgba(224, 226, 228, 1) !important",
        textTransform: "none",
        lineHeight: "100%",
        transition: "all .3s",
        height: "48px",
        boxShadow: "none !important",
        margin: "0 !important",
        background: "rgba(255, 255, 255, 1) !important",

        "& > *": {
          margin: "0 !important",
          padding: "0 !important",
        },

        "&:hover": {
          background: "#fdfdfd !important",
        },

        "& svg": {
          height: "18px",
          width: "18px",
        },
      },
    },
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",

    "& label": {
      fontSize: "12px",
      fontWeight: "500",
      color: "rgba(65, 80, 88, 1)",
    },
  },

  actions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "32px",

    "& button": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "rgba(10, 114, 195, 1)",
      transition: "all .3s",
      height: "48px",
      "&:hover": { background: "#085b9c" },
      "&:disabled": { background: "#84b8e1", borderColor: "#84b8e1" },
    },

    "& span": {
      fontSize: "16px",
      fontWeight: "400",
      color: "rgba(65, 80, 88, 1)",
    },

    "& a": {
      fontSize: "16px",
      fontWeight: "600",
      color: "rgba(10, 114, 195, 1)",
      textDecoration: "none",
      cursor: "pointer",
    },
  },
};

// ----------------------------------------------------------------------------

const StyledTextField = styled(TextField)`
  border: 1px solid rgba(224, 226, 228, 1);
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  & input {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    color: #1f292e;
    padding: 12px 24px 12px 12px;

    &::placeholder {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      color: #a0a7ab;
      font-style: normal;
    }
    &:focus {
      border-color: rgba(10, 114, 195, 1);
    }
  }
`;

function ForgotPassword({ action }) {
  const theme = useTheme();
  const smallDisplay = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);

  const {
    control: formController,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm({
    defaultValues: {},
    values: {},
  });

  const handleSendEmail = async (data) => {
    try {
      setIsLoading(true);

      const response = await ForgotPasswordService(data.email);
      const json = await response.json();

      if (json?.email) setSubmitResponse(json.email);
      if (response.status === 200) {
        navigate("/onboarding/EmailVerification", { state: { action: "resetPassword", email: data.email } });
      }
    } catch (err) {
      setSubmitResponse("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // --------------------------------------------------------------------------------------------
  // COMPONENT RENDERING
  // --------------------------------------------------------------------------------------------

  return (
    <Box sx={styles.container}>
      <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
        <h1>{action === "reset" ? "Forgot password ?" : "Update password"}</h1>
        <span style={{ fontSize: "14px", fontWeight: "500", color: "rgba(65, 80, 88, 1)", textAlign: "center" }}>
          {action === "reset"
            ? "No worries, just enter your email adress below -- we'll send you a link to reset it."
            : "Enter your email adress below to receive a password reset link."}
        </span>
      </Box>
      {!!submitResponse && (
        <Box sx={commonStyles.globalError}>
          <MdErrorOutline />
          <span>{submitResponse}</span>
        </Box>
      )}
      <Form control={formController} onSubmit={handleSubmit(handleSendEmail)}>
        <Box sx={{...styles.form, minWidth: smallDisplay ? "auto" : "430px", marginInline: smallDisplay ? "16px" : "0" }}>
          <Box sx={styles.formGroup}>
            <FormLabel htmlFor="email">Email adress</FormLabel>
            <Controller
              name="email"
              control={formController}
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Invalid email address",
                },
              }}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  id="email"
                  variant="outlined"
                  placeholder="Email"
                  error={!!errors?.email}
                  autoComplete="off"
                  sx={{
                    ...(!!errors?.email && {
                      "& input": { color: "var(--color-red)", borderColor: "var(--color-red)" },
                    }),
                  }}
                />
              )}
            />
            {!!errors?.email && (
              <p style={{ ...commonStyles.errMsg, alignSelf: "flex-end" }}>{errors?.email?.message}</p>
            )}
          </Box>
          <Box sx={styles.actions}>
            <StyledButton
              sx={{ "& span": { color: "white !important", width: "24px !important", height: "24px !important" } }}
              disabled={!isDirty}
            >
              {isLoading ? <CircularProgress /> : "Send link"}
            </StyledButton>
            <span>
              <Link to={action === "reset" ? "/login" : "/Account"}>
                {action === "reset" ? "Back to login" : "Back to your account"}
              </Link>
            </span>
          </Box>
        </Box>
      </Form>
    </Box>
  );
}

export default ForgotPassword;
