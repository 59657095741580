// -----------------------------------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------------------------------
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { Controller, Form, useForm } from "react-hook-form";
import { handleSuccessLogin } from "./Common";

import { Context } from "../../../Store/Store";
import LoginService from "../../../Services/LoginService";
import RegisterNewUserService from "../../../Services/RegisterNewUserService";

import StyledButton from "../../NurtureProspects/ReusableComponents/Button";
import { Box, Button, CircularProgress, TextField, styled } from "@mui/material";
import { BsArrowReturnLeft } from "react-icons/bs";
import { mixpanelCTAEvent, mixpanelEvent  } from "../../../Utils/mixpanelUtils";
// -----------------------------------------------------------------------------------------------------
// STYLES
// -----------------------------------------------------------------------------------------------------

const styles = {
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "24px",
  },
  returnBtn: {
    fontFamily: "Inter",
    display: "flex",
    alignItems: "center",
    gap: "6px",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "14px",
    background: "rgba(10, 114, 195, 0.1)",
    color: "rgba(10, 114, 195, 1)",
    padding: "8px",
    borderRadius: "8px",
    textTransform: "none",
    alignSelf: "flex-start",
    "&:hover": { background: "#f3f8fc" },
    "& svg": {
      height: "16px",
      width: "16px",
    },
  },
  continueBtn: {
    justifyContent: "center",
    background: "rgba(10, 114, 195, 1)",
    padding: "16px",
    transition: "all .3s",
    "&:hover": { background: "#085b9c" },
    "&:disabled": { background: "#84b8e1", borderColor: "#84b8e1" },
  },
  loginBtn: {
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "16px",
    sbackground: "none",
    color: "rgba(10, 114, 195, 1)",
    lineHeight: "100%",
    padding: "0",
    textTransform: "none",
    textDecoration: "underline",
    "&:hover": { background: "none", textDecoration: "underline" },
  },
  errMsg: {
    fontSize: "12px",
    fontWeight: "600",
    color: "var(--color-red)",
    paddingLeft: "8px",
    marginBottom: "0",
  },
};

const StyledTextField = styled(TextField)`
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 8px;
  & input {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    color: #1f292e;
    padding: 12px 24px;

    &::placeholder {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      color: #a0a7ab;
      font-style: normal;
    }
    &:focus {
      border-color: rgba(10, 114, 195, 1);
    }
  }
`;

// -----------------------------------------------------------------------------------------------------
// MAIN
// -----------------------------------------------------------------------------------------------------

function SignupLogin({ step = "", setStep, setIsNewUser }) {
  const context = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [showErr, setShowErr] = useState(false);

  const formDefaultValues =
    step === "signup"
      ? { firstName: "", lastName: "", email: "", password: "" }
      : {
          email: "",
          password: "",
        };

  const {
    setValue: formSetValue,
    control: formController,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: formDefaultValues,
    values: formDefaultValues,
  });

  function filterEmailAlias(_inputString) {
    let inputString = "";
    inputString = _inputString.replace(/\+[^@]+@/, "@");
    return inputString;
  }

  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    const filteredEmail = filterEmailAlias(inputValue);
    formSetValue("email", step === "signup" ? filteredEmail : inputValue, { shouldDirty: true });
  };

  const onContinue = async (formData) => {
    try {
      setIsLoading(true);
      setShowErr(false);
      const email = filterEmailAlias(formData.email);
      let response;
      mixpanelCTAEvent({
        buttonName: "continue",
        ctaPosition: "hero",
        screenName: 'account registration', 
      });
      if (step === "signup") {
        response = await RegisterNewUserService(
          formData.firstName,
          formData.lastName,
          email,
          formData.password,
          "Engage AI"
        );
      } else {
        response = await LoginService({ email, password: formData.password });
      }
      const json = await response.json();

      if (step === "signup") {
        if (response.status === 200 && json?.success === "True") {
          // const d = new Date();
          // d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
          // const expires = "expires=" + d.toUTCString();
          // document.cookie = "fp_email=" + formData.email + ";" + expires + "; path=/";
          setIsNewUser(true);
          setStep("pin");
        } else if (json.message === "Account already exists")
          setShowErr("Account already exists. Please try another email or log in.");
      } else {
        if (response.status === 200) {
          handleSuccessLogin(json, context);
          setIsNewUser(false);
          setStep("pin");
        } else {
          if (json.message === "Incorrect credentials")
            setShowErr("Email or password is incorrect.  Please try again.");
          else throw new Error(json.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // -----------------------------------------------------------------------------------------------------
  // COMPONENT RENDERING
  // -----------------------------------------------------------------------------------------------------
  return (
    <Box sx={styles.content}>
      <Button sx={styles.returnBtn} onClick={() => setStep("welcome")}>
        <BsArrowReturnLeft />
        <span>Return</span>
      </Button>

      <Form style={{ width: "100%" }} control={formController} onSubmit={handleSubmit(onContinue)}>
        <Box sx={{ width: "100%" }} display="flex" flexDirection="column" gap="16px">
          {step === "signup" && (
            <>
              <span style={{ fontSize: "16px", fontWeight: "400" }}>Create an account</span>
              <Box display="flex" alignItems="flex-start" gap="16px">
                <Box display="flex" flexDirection="column" gap="8px">
                  <Controller
                    name="firstName"
                    control={formController}
                    rules={{ required: "First name is required" }}
                    render={({ field }) => (
                      <StyledTextField
                        {...field}
                        variant="outlined"
                        placeholder="First Name"
                        error={!!errors?.firstName}
                      />
                    )}
                  />
                  {!!errors?.firstName && <p style={styles.errMsg}>{errors?.firstName?.message}</p>}
                </Box>
                <Box display="flex" flexDirection="column" gap="8px">
                  <Controller
                    name="lastName"
                    control={formController}
                    rules={{ required: "Last name is required" }}
                    render={({ field }) => (
                      <StyledTextField
                        {...field}
                        variant="outlined"
                        placeholder="Last Name"
                        error={!!errors?.lastName}
                      />
                    )}
                  />
                  {!!errors?.lastName && <p style={styles.errMsg}>{errors?.lastName?.message}</p>}
                </Box>
              </Box>
            </>
          )}
          <Box display="flex" flexDirection="column" gap="8px">
            <Controller
              name="email"
              control={formController}
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Invalid email address",
                },
              }}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  onChange={handleEmailChange}
                  variant="outlined"
                  placeholder="Email"
                  error={!!errors?.email}
                  autoComplete="off"
                />
              )}
            />
            {!!errors?.email && <p style={styles.errMsg}>{errors?.email?.message}</p>}
          </Box>
          <Box display="flex" flexDirection="column" gap="8px">
            <Controller
              name="password"
              control={formController}
              rules={{
                required: "Password is required",
                ...(step === "signup" && {
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters long",
                  },
                  pattern: {
                    value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                    message: "Password must contain at least one letter and one number",
                  },
                }),
              }}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  variant="outlined"
                  placeholder="Password"
                  type="password"
                  error={!!errors?.password}
                  autoComplete="new-password"
                />
              )}
            />
            {!!errors?.password && <p style={styles.errMsg}>{errors?.password?.message}</p>}
          </Box>
          {!!showErr && (
            <Box>
              <p style={{ ...styles.errMsg, marginBottom: "1rem" }}>{showErr}</p>
            </Box>
          )}
          <StyledButton disabled={!isDirty} sx={styles.continueBtn}>
            {isLoading ? <CircularProgress size={24} color="inherit" /> : "Continue"}
          </StyledButton>
        </Box>
      </Form>
      {step === "signup" && (
        <Box display="flex" alignItems="center" mt="8px">
          <span style={{ fontSize: "16px", fontWeight: "500" }}>Already registered ?</span>
          <Button
            sx={styles.loginBtn}
            onClick={() => {
              mixpanelEvent("text_clicked", {
                text: "login",
                screen_name: 'account registration', 
              });
              setShowErr(false);
              setStep("login");
            }}
          >
            Log in
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default SignupLogin;
