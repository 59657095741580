import React, {useRef, useState} from 'react';
import styles from './Onboarding.module.css';
import Topbar from './Topbar';
import Bottombar from './Bottombar';
import { InView } from 'react-intersection-observer';
import { mixpanelEvent } from "../../Utils/mixpanelUtils";

const HowItWorks = () => {
    const [selectedFeature, setSelectedFeature] = useState('lha');
    const lha = useRef(null);
    const lht = useRef(null);
    const ai = useRef(null);
    const ln = useRef(null);
    const fp  = useRef(null);

    const executeScroll = (event) => {
        let id = event.currentTarget.id;
        setSelectedFeature(id);
        if (id === 'lha') {
            lha.current.scrollIntoView();
        }
        else if (id === 'lht') {
            lht.current.scrollIntoView();
        }
        else if (id === 'ai') {
            ai.current.scrollIntoView();
        }
        else if (id === 'ln') {
            ln.current.scrollIntoView();
        }
        else {
            fp.current.scrollIntoView();
        }
    }    

    return (
        <div >
        <div className={`${styles['body']} `}>
            <Topbar/>
            <div className={`${styles['main']} ${styles['center-align']}`}>
                <div style={{fontWeight:"700", fontSize:"34px", lineHeight:"51px", marginBottom:"25px"}}>Toolkit Highlights</div>
                <div style={{fontWeight:"700", fontSize:"22px", lineHeight:"28px", marginBottom:"15px"}}>LinkedIn marketing, accelerated.</div>
                <div style={{fontWeight:"400", fontSize:"16px", lineHeight:"28px", marginBottom:"15px", padding: "0 270px"}}>We designs tools that make reaching and nurturing your target audiences on LinkedIn effortless. <b>Attract more business opportunities in less time.</b></div>
                <div>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/taw9a4t2pbw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
             {/** FILT Pod Platform */}
             <div style={{height:"146px", color:"white", padding: "40px 120px", fontWeight:"700", fontSize:"44px", background:"#3947A8"}}>Features</div>
             <div className={`${styles['main']} ${styles['frow']}`}>
                 {/** LHS menu */}
                <div style={{height:"810px", flexBasis:"25%"}}>
                    <div className={selectedFeature === 'lha' ?  `${styles['rectangle3']} ` : `${styles['rectangle2']} `} onClick={executeScroll} id="lha">
                        <div style={{marginBottom:"5px"}}><img src={selectedFeature === 'lha' ?  '/images/1_white.svg' : '/images/1_blue.svg'} alt='1'></img></div>
                        <div>Hashtag Analytics Extension</div>
                    </div>
                    <div className={selectedFeature === 'lht' ?  `${styles['rectangle3']} ` : `${styles['rectangle2']} `} onClick={executeScroll} id="lht">
                        <div style={{marginBottom:"5px"}}><img src={selectedFeature === 'lht' ?  '/images/2_white.svg' : '/images/2_blue.svg'} alt='2'></img></div>
                        <div>Hashtag Trends</div>
                    </div>
                    <div className={selectedFeature === 'ln' ?  `${styles['rectangle3']} ` : `${styles['rectangle2']} `} onClick={executeScroll} id="ln">
                        <div style={{marginBottom:"5px"}}><img src={selectedFeature === 'ln' ?  '/images/3_white.svg' : '/images/3_blue.svg'} alt='4'></img></div>
                        <div>Engage</div>
                    </div>
                </div>

                {/** RHS */}
                {/** 1. LHA */}
                <div style={{height:"810px", overflow:"scroll"}}>
                    <InView  threshold="0.4" onChange={(inView) => {if(inView){setSelectedFeature("lha")}}}>
                        <div className={`${styles['rectangle4']} `} style={{marginBottom:"25px"}} ref={lha}>
                            <div className={`${styles['rectangle4-1']} ${styles['row']} `}>
                                <div style={{marginRight:"20px"}}><img src='/images/1_white_large.svg' alt='1'></img></div>
                                <div style={{fontSize:"16px", lineHeight:"24px", fontWeight:"400"}}>
                                    <div style={{fontWeight:"700", fontSize:"32px", lineHeight:"48px"}}>Hashtag Analytics Extension</div>
                                    <div>Which hashtags are not trending?</div>
                                    <div>And in which number of followers is steadily growing?</div>
                                    <div>Monitor the behavior of your favorite saved hashtags with ease!</div>
                                </div>
                            </div>
                            <div className={`${styles['rectangle4-2']}`}>
                            <div><img src='/images/lha_description.jpg' alt=''></img></div>
                            </div>
                        </div>
                    </InView>
                    {/** 2. LHT */}
                    <InView  threshold="0.4" onChange={(inView) => {if(inView){setSelectedFeature("lht")}}}>
                        <div className={`${styles['rectangle4']} `} style={{marginBottom:"25px"}} ref={lht}>
                            <div className={`${styles['rectangle4-1']} ${styles['row']} `}>
                                <div style={{marginRight:"20px"}}><img src='/images/2_white_large.svg' alt='1'></img></div>
                                <div style={{fontSize:"16px", lineHeight:"24px", fontWeight:"400"}}>
                                    <div style={{fontWeight:"700", fontSize:"32px", lineHeight:"48px"}}>Hashtag Trends</div>
                                    <div>A <b>smart space</b> where your favourite saved hashtags go.</div>
                                    <div>Monitor <b>behavioural trends</b>. Rapid follower growth – or alarming decline?</div>
                                    <div>Seize the best hashtags for more <b>content exposure</b>!</div>
                                </div>
                            </div>
                            <div className={`${styles['rectangle4-2']}`}>
                            <div><img src='/images/hashtag_trends_description.jpg' alt=''></img></div>
                            </div>
                        </div>
                    </InView>
                    
                    {/** 3. Engage */}
                    <InView  threshold="0.4" onChange={(inView) => {if(inView) {setSelectedFeature("ln")}}}>
                        <div className={`${styles['rectangle4']} `} style={{marginBottom:"25px"}} ref={ln}>
                            <div className={`${styles['rectangle4-1']} ${styles['row']} `}>
                                <div style={{marginRight:"20px"}}><img src='/images/3_white_large.svg' alt='1'></img></div>
                                <div style={{fontSize:"14px", lineHeight:"24px", fontWeight:"400"}}>
                                    <div style={{fontWeight:"700", fontSize:"32px", lineHeight:"48px"}}>Engage</div>
                                    <div>A personalised <b>lead monitoring space</b> for engaging <b>multiple leads at once</b>.</div>
                                    <div>Add key leads to list. Stay on top of <b>every latest post and update</b> in one place.</div>
                                    <div>Receive <b>intelligent comment suggestions</b>* that capture your prospects' attention.</div>
                                    <div><b>Save hours</b> of your time and gain abundantly more <b>business opportunities</b>.</div>
                                        <div style={{ fontStyle: "italic", fontSize: "12px" }}>*Also available as a
                                            <a
                                                href="https://chrome.google.com/webstore/detail/engage-ai/nelhhkchoapcbpcgpmmiahfkcdhgecaf?hl=en-GB&authuser=0"
                                                style={{ color: "white", textDecoration: "underline" }}
                                            >
                                                Chrome Extension
                                            </a>.
                                            <a
                                                href="https://filtpod.com/engage-ai/"
                                                style={{ color: "white", textDecoration: "underline" }}
                                                onClick={() => {
                                                    mixpanelEvent("section_expanded", {
                                                        is_readmore: true,
                                                        section_title: "*Also available as a Chrome Extension.",
                                                        screen_name: "Toolkit Highlights",
                                                    });
                                                }}
                                            >
                                                Learn More
                                            </a>
                                        </div>
                                </div>
                            </div>
                            <div className={`${styles['rectangle4-2']}`}>
                            <div><img src='/images/monitor_description.jpg' alt=''></img></div>
                            </div>
                        </div>
                    </InView>
            
                </div>
             </div>
            <Bottombar/>
        </div>
        </div>
    );
}
export default HowItWorks;
