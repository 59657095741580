import { Paper, Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { BsChatLeftTextFill } from "react-icons/bs";

import Modal from "../NurtureProspects/ReusableComponents/Modal";
import DeleteTone from "./Modals/DeleteTone";
import AddUpdateTone from "./Modals/AddUpdateTone";
import SucessfullUpdate from "./Modals/SucessfullUpdate";
import { useState } from "react";
import { mixpanelEvent } from "../../Utils/mixpanelUtils";

const styles = {
  Paper: {
    borderRadius: "7px",
    border: "1px solid #E0E2E4",
    boxShadow: "none",
    height: "197px",
    fontFamily: "inter",
  },
  toneHeader: {
    display: "flex",
    alignItems: "center",
    padding: "16px",
    borderBottom: "1px solid #E0E2E4",
    color: "#1F292E",
  },
  headerText: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    width: "70%",
    "& span": {
      fontWeight: "600",
      fontSize: "16px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
    },
    "& svg": {
      width: "16px",
      height: "16px",
      color: "#1F292E",
      transform: "translateY(1.5px)",
    },
  },
  headerButtons: {
    display: "flex",
    alignItems: "center",
    "& button": {
      "& svg": {
        width: "18px",
        height: "18px",
      },
      "&:first-child": {
        color: "#1F292E",
      },
      "&:last-child": {
        color: "#D61F1F",
      },
    },
  },
  toneBody: {
    padding: "16px",
  },
  bodyContent: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#415058",
    padding: "16px",
    background: "#F5F5FB",
    borderRadius: "8px",
    height: "95px",
    "& p": {
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": "3",
      overflow: "hidden",
      textOverflow: "ellipsis",
      margin: "0",
    },
  },
};

function Tone({ tone, premiumUser, onAction }) {
  const [showSucessModal, setShowSucessModal] = useState(false);
  const theme = useTheme();
  const bigScreen = useMediaQuery(theme.breakpoints.up("xl"));
  return (
    <Modal>
      <Paper sx={styles.Paper}>
        <Box sx={styles.toneHeader}>
          <Box sx={styles.headerText}>
            <BsChatLeftTextFill />
            <span>{tone.label}</span>
          </Box>
          <Box sx={styles.headerButtons}>
            <Modal.Open
              opens="updateTone"
              onClick={() => {
                mixpanelEvent("icon_clicked", {
                  icon: 'pencil',
                  action: 'edit tone',
                  screen_name: 'customize-ai',
                });
              }}    
            >
              <IconButton>
                <img src="/images/editTone.svg" alt="edit icon" />
              </IconButton>
            </Modal.Open>
            <Modal.Open opens="deleteTone">
              <IconButton>
                <RiDeleteBin6Fill />
              </IconButton>
            </Modal.Open>
          </Box>
        </Box>
        <Box sx={styles.toneBody}>
          <Modal.Open opens="updateTone">
            <Box sx={styles.bodyContent}>
              <p>{tone.prompt}</p>
            </Box>
          </Modal.Open>
        </Box>
      </Paper>

      <Modal.Window
        name="updateTone"
        onClose={() => {
          mixpanelEvent("dialog_closed", {
          dialog_section: 'edit a tone',
          screen_name: 'customize-ai',
        });
        }}
        closeIcon={true}
        modalStyles={{ width: bigScreen ? "43%" : "40%" }}
      >
        <AddUpdateTone
          mode="update"
          tone={tone}
          premiumUser={premiumUser}
          onAddUpdate={onAction}
          setShowSucessModal={setShowSucessModal}
        />
      </Modal.Window>
      <Modal.Window name="deleteTone">
        <DeleteTone tone={tone} onDelete={onAction} />
      </Modal.Window>
      <Modal.Window name="sucessEdit" shouldOpen={showSucessModal} noPadding>
        <SucessfullUpdate setShowSucessModal={setShowSucessModal} />
      </Modal.Window>
    </Modal>
  );
}

export default Tone;
