/*global chrome*/

import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import moment from "moment";

import { Context } from "../Store/Store";
import CheckUserPlanService from "../Services/CheckUserPlan";
import refreshTokenService from "../Services/RefreshToken";

import { constants } from "../Utils/constants";

const GlobalContext = createContext({});

const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useContext(Context);
  const { email } = state?.user?.data ?? {};

  const user = JSON.parse(localStorage.getItem("user"));
  const savedPlan = user?.group;

  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);

  const checkExtensionInstalled = () => {
    if (typeof chrome !== "undefined" && chrome?.runtime) {
      chrome.runtime.sendMessage(constants.EXTENSION_ID, { type: "hello" }, (response) => {
        if (response && response.installed) {
          setIsExtensionInstalled(true);
        } else {
          setIsExtensionInstalled(false);
        }
      });
    } else {
      setIsExtensionInstalled(false);
    }
  };

  const checkUserPlan = useCallback(() => {
    if (!email) return;
    CheckUserPlanService(email).then((res) => {
      res.json().then((json) => {
        if (json.Plan && savedPlan && json.Plan !== savedPlan) {
          // user plan has changed => log out user
          const lastLogin = localStorage.getItem("lastLogin");
          localStorage.clear();
          if (lastLogin !== null) {
            localStorage.setItem("lastLogin", lastLogin);
          }
          // clear state
          dispatch({
            type: "RESET_STATE",
          });
        }
      });
    });
  }, [email, savedPlan, dispatch]);

  const checkTokenExpiration = useCallback(() => {
    const date = moment().format("YYYY-MM-DD HH:mm:ss");
    const token_exp = localStorage.getItem("tokenExp");

    if (!token_exp) return;
    if (moment(date).isBefore(token_exp)) return;

    // token expired, get new token
    refreshTokenService().then((res) => {
      res.json().then((json) => {
        if (res.status === 200) {
          const { access, refresh } = json;
          const newExpire = moment().add(1, "days").format("YYYY-MM-DD HH:mm:ss");

          localStorage.setItem("token", access);
          localStorage.setItem("refreshToken", refresh);
          localStorage.setItem("tokenExp", newExpire);
        }
        else {
          localStorage.setItem("token", "");
        }
      });
    });
  }, []);

  useEffect(() => {
    checkExtensionInstalled();

    const interval = setInterval(() => {
      checkUserPlan();
      checkTokenExpiration();
    }, 30 * 60 * 1000);

    return () => clearInterval(interval);
  }, [checkUserPlan, checkTokenExpiration]);

  return (
    <GlobalContext.Provider value={{ isExtensionInstalled, checkExtensionInstalled }}>
      {children}
    </GlobalContext.Provider>
  );
};

const useGlobalContext = () => useContext(GlobalContext);

export { GlobalProvider, useGlobalContext };
