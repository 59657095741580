// --------------------------------------------------------------------------
// IMPORTS
// --------------------------------------------------------------------------
import { useContext, useEffect } from "react";
import { Context } from "../../Store/Store";
import { useGlobalContext } from "../../Context/GlobalContext"

import { Dialog, DialogContent, Grid, Box, Button } from "@mui/material";

import { MdOutlineMessage } from "react-icons/md";
import { FaPenToSquare } from "react-icons/fa6";
import { MdGroups } from "react-icons/md";

// --------------------------------------------------------------------------
// STYLES
// --------------------------------------------------------------------------

const styles = {
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    padding: "32px",
    fontFamily: "Inter, sans-serif",

    "& h2": {
      fontSize: "24px",
      fontWeight: "600",
      color: "#1F292E",
      margin: 0,
    },
  },
  features: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    listStyle: "none",
    padding: 0,
  },
  feature: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    padding: "16px",
    borderRadius: "8px",
    border: "1px solid #E0E2E4",
  },
  featureIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#E7F1F9",
    borderRadius: "4px",
    height: "34px",
    width: "34px",
    "& svg": {
      height: "18px",
      width: "18px",
      color: "#0A72C3",
    },
  },
  featureText: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",

    "& h3": {
      fontSize: "14px",
      fontWeight: "600",
      color: "#1F292E",
      margin: 0,
    },
    "& span": {
      fontSize: "12px",
      color: "#415058",

      "& a": {
        "&:link, &:visited, &:hover, &:active": {
          fontSize: "12px !important",
          fontWeight: "600",
          textDecoration: "none",
          color: "#0A72C3",
        },
      },
    },
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    width: "100%",

    "& button": {
      textTransform: "none",
      fontWeight: "600",

      "&:first-child": {
        color: "#fff",
        background: "#0A72C3",
        borderRadius: "8px",
        padding: "8px 24px",
        textAlign: "center",
        transition: "all .3s",

        "&:hover": {
          background: "#0A72C3",
        },
      },

      "&:last-child:not(:only-child)": {
        color: "#0A72C3",
        background: "transparent",
        textAlign: "center",

        "&:hover": {
          background: "transparent",
        },
      },
    },
  },
  bgImage: {
    height: "100%",
    background: "url(/images/engageAIFeatures.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
};

// --------------------------------------------------------------------------
// MAIN
// --------------------------------------------------------------------------

function EngageAIFeaturesDialog(props) {
  const { open, onClose } = props;
  const [state] = useContext(Context);
  const { isExtensionInstalled, checkExtensionInstalled } = useGlobalContext()

  const hasPodAccess = state.user?.data?.podAccess;

  const handleOpenExtensionPage = () => {
    const userAgent = navigator.userAgent;
    let browserName = "";

    if (userAgent.indexOf("Firefox") > -1) {
      browserName = "Firefox";
    } else if (userAgent.indexOf("Chrome") > -1) {
      browserName = "Chrome";
    }

    switch (browserName) {
      case "Firefox":
        window.open(
          "https://addons.mozilla.org/fr/firefox/addon/engage-ai/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search",
          "_blank"
        );
        break;

      case "Chrome":
        window.open(
          "https://chromewebstore.google.com/detail/engage-ai-chatgpt-for-soc/nelhhkchoapcbpcgpmmiahfkcdhgecaf?utm_source=ext_app_menu",
          "_blank"
        );
        break;

      default:
        window.open(
          "https://chromewebstore.google.com/detail/engage-ai-chatgpt-for-soc/nelhhkchoapcbpcgpmmiahfkcdhgecaf?utm_source=ext_app_menu",
          "_blank"
        );
        break;
    }
  };

  useEffect(() => {
    checkExtensionInstalled();
  },[checkExtensionInstalled])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: "16px",
        },
      }}
    >
      <DialogContent sx={{ padding: "0 !important" }}>
        <Grid container>
          <Grid item md={6.5} xs={12}>
            <Box sx={styles.contentContainer}>
              <h2>
                All done! <br />
                Start using Engage AI now.
              </h2>
              <ul style={styles.features}>
                {hasPodAccess && (
                  <li style={styles.feature}>
                    <Box sx={{ ...styles.featureIcon, background: "#FDF7EC" }}>
                      <MdOutlineMessage style={{ color: "#EAAB3E" }} />
                    </Box>
                    <Box sx={styles.featureText}>
                      <h3>LinkedIn engagement community</h3>
                      <span>
                        Start building meaningful connections.
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://oatfqxaol.gleap.help/en/articles/2-getting-to-know-the-pod-community-app"
                          aria-label="Read more about how to start building meaningful connections"
                        >
                          Read more
                        </a>
                      </span>
                    </Box>
                  </li>
                )}
                <li style={styles.feature}>
                  <Box sx={styles.featureIcon}>
                    <MdOutlineMessage />
                  </Box>
                  <Box sx={styles.featureText}>
                    <h3>Your LinkedIn comments, powered by AI</h3>
                    <span>
                      Engage and converse with an AI.
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://help.engage-ai.co/en/articles/7-how-to-use-engage-ai-inside-linkedin"
                        aria-label="Read more about how to Engage and converse with an AI"
                      >
                        Read more
                      </a>
                    </span>
                  </Box>
                </li>
                <li style={styles.feature}>
                  <Box sx={styles.featureIcon}>
                    <MdGroups />
                  </Box>
                  <Box sx={styles.featureText}>
                    <h3>Save and monitor your prospects</h3>
                    <span>
                      Be the first to engage with targeted prospects.
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://help.engage-ai.co/en/articles/8-how-to-monitor-and-be-the-first-to-engage"
                        aria-label="Read more about how to monitor and be the first to engage"
                      >
                        Read more
                      </a>
                    </span>
                  </Box>
                </li>
                <li style={styles.feature}>
                  <Box sx={styles.featureIcon}>
                    <FaPenToSquare />
                  </Box>
                  <Box sx={styles.featureText}>
                    <h3>Create quality content with AI</h3>
                    <span>
                      Generate leads with AI-powered content.
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://help.engage-ai.co/en/articles/25-how-to-create-compelling-and-quality-content-on-linkedin"
                        aria-label="Read more about how to generate leads with AI-powered content"
                      >
                        Read more
                      </a>
                    </span>
                  </Box>
                </li>
              </ul>
              <Box sx={styles.actions}>
                <Button onClick={onClose}>Start discovering Engage AI</Button>
                {!isExtensionInstalled && (
                  <Button onClick={handleOpenExtensionPage}>Install the chrome extension</Button>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item md={5.5} xs={12} sx={{ background: "#0A72C3" }}>
            <Box sx={styles.bgImage} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default EngageAIFeaturesDialog;
