export default function LoadTestimonialsService() {
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return fetch("https://cphd4lbk36lgxnv3yu6ivvlyzi0hmbey.lambda-url.ap-southeast-2.on.aws/", options);
}
