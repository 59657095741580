// ----------------------------------------------------------------------------
// IMPORTS
// ----------------------------------------------------------------------------
import { useLocation } from "react-router-dom";

import { StyledLink } from "../NurtureProspects/ReusableComponents/StyledLink";

import { Box, Button, useTheme, useMediaQuery } from "@mui/material";

import { MdMarkEmailRead } from "react-icons/md";

// ----------------------------------------------------------------------------
// STYLES
// ----------------------------------------------------------------------------

const styles = {
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "48px",
    background: "rgba(249, 251, 253, 1)",
    width: "100%",
    height: "100%",
    minHeight: "100dvh",
  },
  logo: {
    position: "absolute",
    left: "24px",
    top: "32px",
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "88px",
    width: "88px",
    borderRadius: "16px",
    border: "1px solid rgba(0, 0, 0, 0.1)",

    "& svg": {
      height: "40px",
      width: "40px",
    },
  },
  text: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
    maxWidth: "570px",
    textAlign: "center",

    "& h1": {
      fontSize: "32px",
      fontWeight: "600",
      fontFamily: "Inter, sans-serif",
      color: "rgba(65, 80, 88, 1)",
      margin: "0",
    },

    "& span": {
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "Inter, sans-serif",
      color: "rgba(65, 80, 88, 1)",
    },
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "24px",
    width: "100%",

    "& button": {
      display: "flex",
      alignItems: "center",
      gap: "12px",

      maxHeight: "48px",
      padding: "16px 24px",
      border: "2px solid #4285F4",
      borderRadius: "8px",
      textTransform: "none",

      "&:hover": {
        background: "rgb(66, 133, 244, 0.05)",
      },

      "& img": {
        height: "14px",
        width: "14px",
      },

      "& span": {
        color: "#4285F4",
        fontSize: "14px",
        fontWeight: "600",
      },
    },
  },
};

// ----------------------------------------------------------------------------

const emailProviders = [
  {
    text: "Open Gmail",
    icon: "/images/gmailIcon.svg",
    iconAlt: "Gmail",
    extensions: ["gmail.com"],
    desktopLink: "https://mail.google.com/mail/u/0/#search/from%3A(account%40engage-ai.co)+in%3Aanywhere+newer_than%3A1h",
    mobileLink: "googlegmail://",
  },
  {
    text: "Open Outlook",
    icon: "/images/outlookIcon.svg",
    iconAlt: "Outlook",
    extensions: ["outlook.com", "outlook.fr", "hotmail.com", "live.com", "msn.com"],
    desktopLink: "https://outlook.live.com/mail/0/",
    mobileLink: "ms-outlook://",
  },
  {
    text: "Open Yahoo",
    icon: "/images/yahooIcon.svg",
    iconAlt: "Yahoo",
    extensions: ["yahoo.com", "yahoo.fr", "ymail.com", "rocketmail.com"],
    desktopLink: "https://mail.yahoo.com/d/search/keyword=from%253Aaccount%2540engage-ai.co",
    mobileLink: "ymail://",
  },
  {
    text: "Open ProtonMail",
    icon: "/images/protonmailIcon.svg",
    iconAlt: "ProtonMail",
    extensions: ["protonmail.com", "protonmail.ch"],
    desktopLink: "https://mail.proton.me/u/0/all-mail#from=account%40engage-ai.co",
    mobileLink: "protonmail://",
  },
  {
    text: "Open Icloud Mail",
    icon: "/images/icloudIcon.svg",
    iconAlt: "Icloud Mail",
    extensions: ["icloud.com", "me.com", "mac.com"],
    desktopLink: "https://www.icloud.com/mail/",
    mobileLink: "message://",
  },
];

const getProvider = (email) => {
  if (!email) return null;
  const domain = email.split("@")[1];
  return emailProviders.find((provider) => provider.extensions.includes(domain));
};

const isMobileDevice = () => {
  return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

// ----------------------------------------------------------------------------
// MAIN COMPONENT
// ----------------------------------------------------------------------------

function EmailVerification() {
  const theme = useTheme();
  const smallDisplay = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const { action, email } = location.state || {};

  const provider = getProvider(email);

  const handleOpenMailApp = () => {
    if (isMobileDevice()) {
      window.location.href = provider.mobileLink;
    } else {
      window.open(provider.desktopLink, "_blank");
    }
  };

  const handleOpenGmail = () => {
    if (isMobileDevice()) {
      window.location.href = emailProviders[0].mobileLink;
    } else {
      window.open(emailProviders[0].desktopLink, "_blank");
    }
  };

  const handleOpenOutlook = () => {
    if (isMobileDevice()) {
      window.location.href = emailProviders[1].mobileLink;
    } else {
      window.open(emailProviders[1].desktopLink, "_blank");
    }
  };

  // ----------------------------------------------------------------------------
  // COMPONENT RENDERING
  // ----------------------------------------------------------------------------
  return (
    <Box sx={styles.container}>
      <Box sx={styles.content}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="48px" mx={smallDisplay ? "16px" : "0"}>
          <Box display="flex" flexDirection="column" alignItems="center" gap="32px">
            <Box
              sx={{
                ...styles.icon,
                background: "rgba(100, 190, 55, 1)",
                color: "#fff",
              }}
            >
              <MdMarkEmailRead />
            </Box>
            <Box sx={styles.text}>
              <h1>{action === "resetPassword" ? "Check your inbox" : "Your account is created!"}</h1>
              <span>
                We have sent a verification email to <strong>{email}</strong>. Please check your inbox and follow the
                instructions to {action === "resetPassword" ? "update your password" :"confirm your account"}.
              </span>
            </Box>
          </Box>
          <Box sx={{...styles.buttons, flexDirection: smallDisplay ? "column" : "row"}}>
            {provider ? (
              <Button onClick={handleOpenMailApp}>
                <img src={provider.icon} alt={provider.iconAlt} />
                <span>{provider.text}</span>
              </Button>
            ) : (
              <>
                <Button onClick={handleOpenGmail}>
                  <img src="/images/gmailIcon.svg" alt="Gmail" />
                  <span>Open Gmail</span>
                </Button>
                <Button onClick={handleOpenOutlook}>
                  <img src="/images/outlookIcon.svg" alt="Outlook" />
                  <span>Open Outlook</span>
                </Button>
              </>
            )}
          </Box>
          <Box>
            <span style={{ fontSize: "14px", fontWeight: "500", color: "rgba(65, 80, 88, 1)" }}>
              {action === "resetPassword" ? "Have you changed your password" : "Have you checked your email"} ? &nbsp;
               <StyledLink to="/login">Log in</StyledLink>
            </span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default EmailVerification;
