//  -----------------------------------------------------------------------------------
// IMPORTS
//  -----------------------------------------------------------------------------------
import { useMemo, useState } from "react";
import { Controller, useWatch } from "react-hook-form";

import { StyledTextField } from "./TextField";
import { commonStyles } from "./CommonStyles";

import { Box, FormLabel, InputAdornment, IconButton } from "@mui/material";
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from "@mui/icons-material";

//  -----------------------------------------------------------------------------------

const sucessColor = "rgba(100, 190, 55, 1)";

function PasswordComponent(props) {
  const { label, control, errors } = props;

  const passwordValue = useWatch({ control, name: "password" });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const isPasswordValid = useMemo(() => {
    if (!passwordValue) return false;
    if (!testPasswordLength(passwordValue)) return false;
    if (!testPasswordNumber(passwordValue)) return false;
    if (!testPasswordUpperCase(passwordValue)) return false;
    return true;
  }, [passwordValue]);

  // -----------------------------------------------------------------------------------
  // MAIN COMPONENT RENDERING
  // -----------------------------------------------------------------------------------

  return (
    <Box sx={commonStyles.formGroup}>
      <FormLabel htmlFor="password">{label}</FormLabel>
      <Controller
        name="password"
        control={control}
        rules={{
          required: "required.",
          pattern: {
            value: /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&#+]{8,}$/
          },
        }}
        render={({ field }) => (
          <StyledTextField
            {...field}
            id="password"
            variant="outlined"
            placeholder={label}
            type={showPassword ? "text" : "password"}
            error={(!!errors?.password && !isPasswordValid) || !!errors?.customError}
            autoComplete="new-password"
            sx={{
              ...(!!errors?.password &&
                !isPasswordValid && {
                  "& input": { color: "var(--color-red)", borderColor: "var(--color-red)" },
                }),
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      {!!errors?.customError && (
        <p style={{ ...commonStyles.errMsg, alignSelf: "flex-end" }}>{errors?.customError?.message}</p>
      )}
      <PasswordIndicator password={passwordValue} />
    </Box>
  );
}


const testPasswordLength = (password) => {
  return password.length >= 8;
};

const testPasswordNumber = (password) => {
  return /\d/.test(password);
};

const testPasswordUpperCase = (password) => {
  return /[A-Z]/.test(password);
};

const testPasswordSpecialChar = (password) => {
  return /[!@#$%^&*(),.?":{}|<>]/.test(password);
};

const PasswordIndicator = ({ password }) => {
  const isLengthValid = testPasswordLength(password);
  const isNumberValid = testPasswordNumber(password);
  const isUpperCaseValid = testPasswordUpperCase(password);
  const isSpecialCharValid = testPasswordSpecialChar(password);
  
  return (
    <Box sx={commonStyles.passwordCriteria}>
      <span>Your password must meet the following criteria:</span>
      <ul>
        <li style={{ ...(isLengthValid && { color: sucessColor }) }}>
          {isLengthValid ? "\u2713" : "\u2716"} &nbsp; Be at least 8 characters long
        </li>
        <li style={{ ...(isNumberValid && { color: sucessColor }) }}>
          {isNumberValid ? "\u2713" : "\u2716"} &nbsp; Contain numbers
        </li>
        <li style={{ ...(isUpperCaseValid && { color: sucessColor }) }}>
          {isUpperCaseValid ? "\u2713" : "\u2716"} &nbsp; Contain uppercase letters
        </li>
        <li style={{ ...(isSpecialCharValid && { color: sucessColor }) }}>
          &ndash; &nbsp; Contain at least one special character (e.g., @, #, $, %, &)
        </li>
      </ul>
    </Box>
  );
};

export default PasswordComponent;