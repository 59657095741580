import { setUserDataLocalStorage } from "../../../Scripts/getSetLocalStorage";
import { TransformAPIDataToUserData } from "../../../Services/UserDataService";

export const handleSuccessLogin = (json, context, withGoogle) => {
  const date = new Date();
  date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
  let expires = "expires=" + date.toUTCString();

  document.cookie = "fp_email=" + json.data.user.email + ";" + expires + "; path=/";
  document.cookie = "fp_user_type=" + json.data.user.group + ";" + expires + "; path=/";
  document.cookie = "fp_user_trial_end_date=" + json.data.user.trial_end_date + ";" + expires + "; path=/";
  document.cookie = "eai_token=" + json.data.token + ";" + expires + "; path=/";

  const data = TransformAPIDataToUserData(json);
  const { token, refresh_token, token_exp } = json.data;
  setUserDataLocalStorage(data, token, refresh_token, withGoogle ? "auth0" : "email", token_exp);

  context[1]({
    type: "SET_USER",
    payload: {
      data,
    },
  });
}