// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------
import { useEffect, useMemo, useState } from "react";
import moment from "moment";

import { Avatar, Box } from "@mui/material";
import { FaStar } from "react-icons/fa6";

// -----------------------------------------------------------------------------
// STYLES
// -----------------------------------------------------------------------------
const styles = {
  testimonials: {
    position: "relative",
    overflow: "hidden",
    height: "205px",
    width: "100%",
    marginTop: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  testimonial: {
    position: "absolute",
    top: 0,
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    borderRadius: "16px",
    padding: "24px",
    transition: "all 1s",
  },
  testimonialContent: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  rating: {
    display: "flex",
    alignItems: "center",
    gap: "2px",

    "& svg": {
      color: "#F7871B",
      height: "14px",
      width: "14px",
    },
  },
  testimonialText: {
    fontSize: "16px",
    lineHeight: "20px",
    color: "#fff",
    fontWeight: "600",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
    overflow: "hidden",
  },
  testimonialAuthor: {
    display: "flex",
    alignItems: "center",
    gap: "12px",

    "& > div": {
      display: "flex",
      flexDirection: "column",
      gap: "4px",

      "& span": {
        color: "#fff",
        "&:first-child": {
          fontSize: "14px",
          fontWeight: "700",
        },
        "&:last-child": {
          fontSize: "12px",
          fontWeight: "500",
          color: "rgb(255, 255, 255, 0.7)",
        },
      },
    },
  },
  authorImg: {
    height: "32px",
    width: "32px",
  },
  buttons: {
    position: "absolute",
    bottom: 0,
    alignSelf: "center",
    display: "flex",
    gap: "8px",

    "& span": {
      height: "6px",
      width: "16px",
      background: "rgb(255, 255, 255, 0.25)",
      borderRadius: "100rem",
      transition: "all 0.3s",
      cursor: "pointer",
      "&:hover, &.active": {
        background: "#fff",
      },
    },
  },
};

// -----------------------------------------------------------------------------

const renderTestimonial = (testimonial, index, activeIndex) => {
  const { rating, text, customer_avatar, customer_name, date } = testimonial || {};
  const activeTestimonial = index === activeIndex;
  return (
    <Box
      key={index}
      sx={{
        ...styles.testimonial,
        opacity: activeTestimonial ? 1 : 0,
        background: activeTestimonial ? "rgb(0, 0, 0, 0.1)" : "transparent",
        transform: `translateX(${100 * (index - activeIndex)}%)`,
      }}
    >
      <Box sx={styles.testimonialContent}>
        <Box sx={styles.rating}>
          {Array.from({ length: rating }, (_, i) => (
            <FaStar key={i} />
          ))}
        </Box>
        <Box sx={styles.testimonialText}>{text}</Box>
      </Box>
      <Box sx={styles.testimonialAuthor}>
        <Avatar sx={styles.authorImg} src={customer_avatar} alt="Author" />
        <Box>
          <span>{customer_name}</span>
          <span>{moment(date).format("DD/MM/YYYY")}</span>
        </Box>
      </Box>
    </Box>
  );
};

function TestimonialsSlider({ testimonials = [], isLoading }) {
  // -----------------------------------------------------------------------------
  // STATE
  // -----------------------------------------------------------------------------
  const [currentIndex, setCurrentIndex] = useState(0);
  const maxTestimonials = testimonials.length;

  // -----------------------------------------------------------------------------

  const sortedTestimonials = useMemo(() => {
    if (!testimonials.length) return [];

    // Sort testimonials => 1/- 5-4 stars + profile photo 2/- 5-4 stars 3/- 3 stars with profile photo

    return testimonials.sort((a, b) => {
      if ((a.rating === 5 || a.rating === 4) && a.customer_avatar) return -1;
      if ((b.rating === 5 || b.rating === 4) && b.customer_avatar) return 1;

      if (a.rating === 5 || a.rating === 4) return -1;
      if (b.rating === 5 || b.rating === 4) return 1;

      if (a.rating === 3 && a.customer_avatar) return -1;
      if (b.rating === 3 && b.customer_avatar) return 1;

      return 0;
    });
  }, [testimonials]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex === maxTestimonials - 1) setCurrentIndex(0);
      else setCurrentIndex((i) => i + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [currentIndex, maxTestimonials]);

  if ((!testimonials || testimonials?.length === 0) && !isLoading) return null;

  // ----------------------------------------------------------------------------
  // COMPONENT RENDERING
  // ----------------------------------------------------------------------------

  return (
    <Box sx={styles.testimonials}>
      {sortedTestimonials.map((review, index) => renderTestimonial(review, index, currentIndex))}
    </Box>
  );
}

export default TestimonialsSlider;
