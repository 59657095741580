import { useContext, useEffect, useMemo, useState } from "react";
import { Context } from "../../Store/Store";
import GetSettingsService from "../../Services/SettingsService";

import { Box, Grid, Paper, Tooltip, useMediaQuery } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { FaPlus } from "react-icons/fa";

import AddTone from "./AddTone";
import ToneLimit from "./Modals/ToneLimit";
import StyledSpinner from "../NurtureProspects/ReusableComponents/StyledSpinner";
import Tone from "./Tone";
import StyledButton from "../NurtureProspects/ReusableComponents/Button";
import Modal from "../NurtureProspects/ReusableComponents/Modal";
import AddUpdateTone from "./Modals/AddUpdateTone";
import SucessfullUpdate from "./Modals/SucessfullUpdate";
import { useTheme } from "@mui/material";
import { mixpanelCTAEvent, mixpanelEvent } from "../../Utils/mixpanelUtils";

const styles = {
  Paper: {
    boxShadow: "none",
    border: "none",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    padding: "1.5rem 2.5rem",
    background: "var(--color-grey-50)",
  },
  PageHeader: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    marginBottom: "1.5rem",
  },
  FilterBox: {
    display: "flex",
    alignItems: "center",
  },
  customizeBtn: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    textTransform: "none",
    padding: "8px 16px",
    "& svg": {
      width: "20px",
      height: "20px",
    },
  },
  saveBtn: {
    margin: "1rem auto",
    padding: "8px 32px",
  },
};

function Index() {
  const context = useContext(Context);
  const state = context[0];
  const userGroup = state.user?.data?.group;
  const premiumUser =
    userGroup === "Growth Plan" ||
    userGroup === "Member" ||
    userGroup === "Starter" ||
    userGroup === "Premium" ||
    userGroup === "Trial";

  const theme = useTheme();
  const bigScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [showSucessAdd, setShowSucessAdd] = useState(false);
  const [showSucessEdit, setShowSucessEdit] = useState(false);
  const [tonesInitialData, setTonesInitialData] = useState([]);
  const [tones, setTones] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshTonesKey, setRefreshTonesKey] = useState(0);

  const tonesLimit = useMemo(() => {
    if (userGroup === "Starter") return 6;
    // Trial users have same limit as Pro users
    if (userGroup === "Growth Plan" || userGroup === "Trial") return 12;
    if (userGroup === "Member" || userGroup === "Premium") return 20;
    // free users
    if (userGroup === "Engage_AI") return 4;
    return 4;
  }, [userGroup]);

  const tooltipText = "You can't create more than 20 tones.";

  useEffect(() => {
    setIsLoading(true);
    GetSettingsService().then((res) => {
      setIsLoading(false);
      res.json().then((json) => {
        setTonesInitialData(json);
      });
    });
  }, [refreshTonesKey]);

  useEffect(() => {
    if (tonesInitialData.length) setTones(tonesInitialData);
  }, [tonesInitialData]);

  return (
    <Modal>
      <Paper sx={styles.Paper}>
        <Box sx={styles.PageHeader}>
          <Box className="headerText">
            <Box display="flex" alignItems="center" gap="8px">
              <img src="/images/pageInfo.svg" alt="settings Icon" />
              <h1>Customize AI</h1>
            </Box>
            <p>
              Create and manage AI-generated tones to enhance your LinkedIn interactions. &nbsp;
              <a
                href="https://bit.ly/EAI-CustomizeTones"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  mixpanelEvent("hyperlink_clicked", {
                    text: 'See documentation',
                    redirection_url: 'https://bit.ly/EAI-CustomizeTones',
                    screen_name: 'customize-ai',
                  });
                }}    
              >
                <span>See documentation</span>
                <LaunchIcon />
              </a>
            </p>
          </Box>
          <Modal.Open
            opens="addTone"
            onClick={() => {
              mixpanelCTAEvent({
                buttonName: "add tone",
                ctaPosition: "right-section",
                screenName: 'customize-ai', 
              });
            }}
          >
            {(userGroup === "Premium" || userGroup === "Member") && tones.length >= tonesLimit ? (
              <Tooltip title={tooltipText} placement="bottom-end">
                <span>
                  <StyledButton disabled={true}>
                    <FaPlus />
                    <span>Add tone</span>
                  </StyledButton>
                </span>
              </Tooltip>
            ) : (
              <StyledButton>
                <FaPlus />
                <span>Add tone</span>
              </StyledButton>
            )}
          </Modal.Open>
        </Box>
        <Grid sx={{ paddingBlock: "2rem" }} container direction="column" spacing={3}>
          <StyledSpinner isLoading={isLoading} />
          <Grid item container spacing={3}>
            {tones.map((tone, index) => {
              return (
                <Grid key={index} item xs={12} md={6} lg={4}>
                  <Tone
                    index={index}
                    premiumUser={premiumUser}
                    tone={tone}
                    onAction={() => setRefreshTonesKey((oldKey) => oldKey + 1)}
                    showSucessModal={showSucessEdit}
                    setShowSucessModal={setShowSucessEdit}
                  />
                </Grid>
              );
            })}
            <Modal.Open
              opens="addTone"
              onClick={() => {
                mixpanelCTAEvent({
                  buttonName: "plus icon",
                  ctaPosition: "bottom-right",
                  screenName: 'customize-ai', 
                });
              }}
            >
              <Grid sx={{ display: "flex", justifyContent: "center" }} item xs={12} md={6} lg={4}>
                <AddTone
                  disabled={(userGroup === "Premium" || userGroup === "Member") && tones.length >= tonesLimit}
                  tooltipText={tooltipText}
                />
              </Grid>
            </Modal.Open>
          </Grid>
        </Grid>
      </Paper>

      {((userGroup !== "Premium" && userGroup !== "Member") ||
        ((userGroup === "Premium" || userGroup === "Member") && tones.length < tonesLimit)) && (
        <Modal.Window
          name="addTone"
          noPadding={tones.length >= tonesLimit ? true : false}
          closeIcon={tones.length >= tonesLimit ? false : true}
          modalStyles={{ width: tones.length >= tonesLimit ? "auto" : bigScreen ? "43%" : "40%" }}
        >
          {tones.length >= tonesLimit ? (
            <ToneLimit />
          ) : (
            <AddUpdateTone
              mode="add"
              premiumUser={premiumUser}
              onAddUpdate={() => setRefreshTonesKey((oldKey) => oldKey + 1)}
              showSucessModal={showSucessAdd}
              setShowSucessModal={setShowSucessAdd}
            />
          )}
        </Modal.Window>
      )}

      <Modal.Window name="sucessAdd" shouldOpen={showSucessAdd} noPadding>
        <SucessfullUpdate setShowSucessModal={setShowSucessAdd} />
      </Modal.Window>
    </Modal>
  );
}

export default Index;
