import React from "react";
import styles from "./Integrations.module.css";
import { Box } from "@mui/material";
import { mixpanelEvent } from "../../Utils/mixpanelUtils";

export default function Integrations() {
  return (
    <div>
      <div
        className="row"
        style={{
          flexWrap: "nowrap",
          padding: "1.5rem 2.5rem",
          flexDirection: "column",
          background: "var(--color-grey-50)",
        }}
      >
        <Box className="headerText" mb="1.5rem">
            <Box display="flex" alignItems="center" gap="8px">
              <h1>Integrations</h1>
            </Box>
            <p>
            Integrate Engage AI with your go-to tools. &nbsp;
            </p>
          </Box>
        <div style={{ flex: "1" }}>
          <div className={`${styles["main"]} mt-2 `}>
            <div className={`${styles["row-wrap"]} mt-2 pt-5 pb-5 `}>
              <div className={`${styles["tile"]}`}>
                <div
                  style={{ textAlign: "center", cursor: "pointer" }}
                  onClick={() => {
                    mixpanelEvent("integration_clicked", {
                      integration_name: "Hootsuite",
                      integration_category: "CRM",
                      screen_name: "Integrations",
                    });
                    window.open("https://apps.hootsuite.com/apps/Engage-AI");
                  }}
                >
                  <img src="/images/hootsuite_logo.png" alt="" style={{ height: "40px" }}></img>
                  <div>Hootsuite</div>
                </div>
              </div>
              <div className={`${styles["tile"]}`}>
                <div
                  style={{ textAlign: "center", cursor: "pointer" }}
                  onClick={() => {
                    mixpanelEvent("integration_clicked", {
                      integration_name: "HubSpot",
                      integration_category: "CRM",
                      screen_name: "Integrations",
                    });
                    window.open(
                      "https://app.hubspot.com/oauth/authorize?client_id=d0d2e82b-5137-4f19-a0bd-c99bcb16e620&redirect_uri=https://prod.engageai-server.com/auth/hubspot/callback&scope=timeline%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.schemas.contacts.read%20crm.schemas.contacts.write"
                    );
                  }}
                >
                  <img src="/images/hubspot_logo.png" alt="" style={{ height: "40px" }}></img>
                  <div>HubSpot</div>
                </div>
              </div>
              <div className={`${styles["tile"]}`}>
                <div
                  style={{ textAlign: "center", cursor: "pointer" }}
                  onClick={() => {
                    mixpanelEvent("integration_clicked", {
                      integration_name: "Integrately",
                      integration_category: "Automation",
                      screen_name: "Integrations",
                    });
                    window.open("https://integrately.com/integrations/engage-ai");
                  }}
                >
                  <img src="/images/integrately_logo.svg" alt="" style={{ height: "35px" }}></img>
                  <div>Integrately</div>
                </div>
              </div>
              <div className={`${styles["tile"]}`}>
                <div
                  style={{ textAlign: "center", cursor: "pointer" }}
                  onClick={() => {
                    mixpanelEvent("integration_clicked", {
                      integration_name: "Pipedrive",
                      integration_category: "CRM",
                      screen_name: "Integrations",
                    });
                    window.open("https://www.pipedrive.com/en/marketplace/app/engage-ai/9abcb1ee32da2065");
                  }}
                >
                  <img src="/images/pipedrive_logo.svg" alt="" style={{ height: "40px" }}></img>
                  <div>Pipedrive</div>
                </div>
              </div>
              <div className={`${styles["tile"]}`}>
                <div
                  style={{ textAlign: "center", cursor: "pointer" }}
                  onClick={() => {
                    mixpanelEvent("integration_clicked", {
                      integration_name: "Zapier",
                      integration_category: "Automation",
                      screen_name: "Integrations",
                    });
                    window.open("https://zapier.com/apps/engage-ai-by-filt-pod/integrations");
                  }}
                >
                  <img src="/images/zapier_logo.png" alt="" style={{ height: "40px" }}></img>
                  <div>Zapier</div>
                </div>
              </div>
              <div className={`${styles["tile"]}`}>
                <div
                  style={{ textAlign: "center", cursor: "pointer" }}
                  onClick={() => {
                    mixpanelEvent("integration_clicked", {
                      integration_name: "Zoho",
                      integration_category: "CRM",
                      screen_name: "Integrations",
                    });
                    window.open(
                      "https://crm.zoho.com/market/installPrivatePlugin.do?portalName=hello22&nameSpace=engageai&versionIndex=4529766000000107009"
                    );
                  }}
                >
                  <img src="/images/zoho_logo.png" alt="" style={{ height: "40px" }}></img>
                  <div>Zoho</div>
                </div>
              </div>
              <div className={`${styles["tile"]}`}>
                <div
                  style={{ textAlign: "center", cursor: "pointer" }}
                  onClick={() => {
                    mixpanelEvent("integration_clicked", {
                      integration_name: "Make",
                      integration_category: "Automation",
                      screen_name: "Integrations",
                    });
                    window.open("https://www.make.com/en/integrations/engage-ai");
                  }}
                >
                  <img src="/images/make_logo.png" alt="" style={{ height: "40px" }}></img>
                  <div>Make</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
