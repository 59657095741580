import { Box, Typography } from "@mui/material";
import { MdDiamond } from "react-icons/md";
import { Link } from "react-router-dom";
import StyledButton from "../../ReusableComponents/Button";
import { mixpanelCTAEvent } from "../../../../Utils/mixpanelUtils";

const styles = {
  Modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "430px",
    overflow: "hidden",
    "& *": {
      margin: " 0",
    },
    "& h3": {
      fontSize: "24px",
      fontWeight: "700",
      color: "#1F292E",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "500",
      color: "#415058",
      lineHeight: "24px",
      marginBottom: "5px",
      textAlign: "center",
      "& span": {
        fontSize: "14px",
        fontWeight: "700",
        color: "#1F292E",
      },
    },
  },
  topBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    background: "linear-gradient(0deg, #D79F3F 0%, #EAAB3E 100%)",

    paddingBlock: "16px",
    "& svg": {
      width: "75px",
      height: "75px",
      color: "white",
    },
  },
};

function UpgradeModal({ onCloseModal }) {
  return (
    <Box sx={styles.Modal}>
      <Box sx={styles.topBox}>
        <MdDiamond />
      </Box>
      <Box sx={{ padding: "32px 24px" }} display="flex" flexDirection="column" alignItems="center" gap="1rem">
        <h3>Upgrade required</h3>
        <Typography>
          You have reached the limit of your actual plan. <br />
          Upgrade to a higher plan to monitor more prospects.
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center" gap=".8rem">
          <Link
            to="https://bit.ly/3xdvInj"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              mixpanelCTAEvent({
                buttonName: "Upgrade Now",
                ctaPosition: "hero",
                screenName: "Upgrade required",
              });
            }}
          >
            <StyledButton size="medium" variation="warning">
              Upgrade Now
            </StyledButton>
          </Link>
          <StyledButton variation="noBackground" onClick={onCloseModal}>
            Maybe later
          </StyledButton>
        </Box>
      </Box>
    </Box>
  );
}

export default UpgradeModal;
