// ----------------------------------------------------------------------------
// IMPORTS
// ----------------------------------------------------------------------------
import { useState, useContext } from "react";
import { Form, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Context } from "../../Store/Store";

import { commonStyles } from "./Common/CommonStyles";
import PasswordComponent from "./Common/PasswordComponent";
import StyledButton from "../NurtureProspects/ReusableComponents/Button";

import KeyIcon from "@mui/icons-material/Key";
import { Box, CircularProgress, useTheme, useMediaQuery } from "@mui/material";
import ResetPasswordService from "../../Services/ResetPasswordService";

// ----------------------------------------------------------------------------
// STYLES
// --------------------------------------------------------------------------
const styles = {
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "48px",
    background: "rgba(249, 251, 253, 1)",
    width: "100%",
    height: "100%",
    minHeight: "100dvh",
  },
  logo: {
    position: "absolute",
    left: "24px",
    top: "32px",
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "88px",
    width: "88px",
    borderRadius: "16px",
    border: "1px solid rgba(0, 0, 0, 0.1)",

    "& svg": {
      height: "40px",
      width: "40px",
    },
  },
  text: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
    "& h1": {
      fontSize: "32px",
      fontWeight: "600",
      fontFamily: "Inter, sans-serif",
      color: "rgba(65, 80, 88, 1)",
      margin: "0",
      textAlign: "center",
    },

    "& span": {
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "Inter, sans-serif",
      color: "rgba(65, 80, 88, 1)",
    },
  },
};

// --------------------------------------------------------------------------

function ResetPassword() {
  const theme = useTheme();
  const smallDisplay = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const searchParams = useSearchParams();
  const [, dispatch] = useContext(Context);

  const token = searchParams[0].get("token");

  // ----------------------------------------------------------------------------
  // STATE
  // ----------------------------------------------------------------------------

  const [submitError, setSubmitError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState("reset");

  // ----------------------------------------------------------------------------
  // REACT HOOK FORM
  // ----------------------------------------------------------------------------

  const {
    control: formController,
    formState: { errors: formErrors },
    handleSubmit,
  } = useForm({
    defaultValues: { password: "" },
    values: { password: "" },
  });

  const handleResetPassword = async (data) => {
    try {
      if (step === "finish") {
        navigate("/login");
        return;
      }
      setIsLoading(true);
      const payload = {
        token,
        password1: data.password,
      };
      const response = await ResetPasswordService(payload);
      if (response.status === 200) {
        setStep("finish");
        // log out user
        const lastLogin = localStorage.getItem("lastLogin");
        localStorage.clear();
        if (lastLogin !== null) {
          localStorage.setItem("lastLogin", lastLogin);
        }
        // clear state
        dispatch({
          type: "RESET_STATE",
        });
      }
    } catch (err) {
      setSubmitError("Oops, an error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  // ----------------------------------------------------------------------------
  // COMPONENT RENDERING
  // ----------------------------------------------------------------------------

  return (
    <Box sx={styles.container}>
      <Box sx={styles.logo}>
        <img src="/images/engageAiBlueLogo.svg" alt="Engage AI" />
      </Box>
      <Box sx={{...styles.content, marginInline: smallDisplay ? "16px" : "0" }}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="48px">
          <Box display="flex" flexDirection="column" alignItems="center" gap="32px">
            <Box
              sx={{
                ...styles.icon,
                background: step === "reset" ? "rgba(242, 242, 243, 1)" : "rgba(100, 190, 55, 1)",
                color: step === "reset" ? "#000" : "#fff",
                mt: smallDisplay ? "32px" : "0",
              }}
            >
              <KeyIcon />
            </Box>
            <Box sx={styles.text}>
              {step === "reset" ? (
                <h1>Set new password</h1>
              ) : (
                <h1>
                  Your password has been <br /> successfully updated!
                </h1>
              )}
              <span>
                {step === "reset"
                  ? "Choose a secure one and make sure to remember it."
                  : "You can now log in with your new password."}
              </span>
            </Box>
          </Box>
          <Form style={{ minWidth: smallDisplay ? "auto" : "430px"}} control={formController} onSubmit={handleSubmit(handleResetPassword)}>
            <Box display="flex" flexDirection="column" gap="8px" sx={{ "& label": { fontWeight: "600" } }}>
              {step === "reset" && (
                <PasswordComponent
                  label="New password"
                  control={formController}
                  errors={{ ...formErrors, ...(submitError && { customError: { message: submitError } }) }}
                />
              )}
              <Box
                sx={commonStyles.actions}
                alignSelf="center"
                width={step === "reset" ? "100%" : "fit-content"}
                mt="6px"
              >
                <StyledButton
                  sx={{ "& span": { color: "white", width: "24px !important", height: "24px !important" } }}
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress /> : step === "reset" ? "Reset password" : "Back to Login"}
                </StyledButton>
              </Box>
            </Box>
          </Form>
        </Box>
      </Box>
    </Box>
  );
}

export default ResetPassword;
