import React, { useState, useEffect, useCallback } from "react";
import GetCutoffTime from "../../Services/GetCutoffTime";
//import GetTimeZoneAbbreviation from "./Timezone";
import { checkServiceResponse } from "../../Services/AuthService";
import { useNavigate } from "react-router-dom";
//import { formatAMPM } from "../../Scripts/12to24Time";
//import styles from './LinkSubmitPage.module.css';

const CutoffTimeComponent = ({ checkEnabled, isEnabled, setIsLoading, podID}) => {
  const [timevalues, setTimeValues] = useState({});
  //const [secondsOffset, setSecondsOffset] = useState(0);
  //const [timeLeftState, setTimeLeft] = useState();
  let history = useNavigate();
  const [time, setTime] = useState();
  const [starttime, setStarttime] = useState();
  const [date, setDate] = useState();
  const [weekday, setWeekday] = useState();
  const [utcOffset, setUtcOffset] = useState();

  const refreshCutoffTimeData = useCallback(() => {
    setIsLoading(true);
    console.log(time, starttime, date, weekday, utcOffset)
    GetCutoffTime(podID)
      .then((res) => {
        res.json().then((json) => {
          let checkServiceRes = checkServiceResponse(json, res);
          if (res.status !== 200 && checkServiceRes["pathname"]) {
            // console.log("redirect");
            //if(checkServiceRes.redirectPath) {
            history.push(checkServiceRes.pathname);
            // }
          } else {
            let data = json.data;
            setTimeValues(data);
            setWeekday(data.tz_object['Australia/Brisbane']['weekday'])
            setDate(data.tz_object['Australia/Brisbane']['date'])
            setTime(toStandardTime(data.tz_object['Australia/Brisbane']['time'].replace(/^0(?=[123456789])/, "").replace(/:00$/, "")))
            setStarttime(toStandardTime(data.tz_object['Australia/Brisbane']['start_time'].replace(/^0(?=[123456789])/, "").replace(/:00$/, "")))
            setUtcOffset(data.tz_object['Australia/Brisbane']['utc_offset'])

            setIsLoading(false);
          }
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }, [setIsLoading, history, podID, time, starttime, date, weekday, utcOffset]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      refreshCutoffTimeData();
    }
    return () => (isSubscribed = false);
  }, [refreshCutoffTimeData]);

  useEffect(() => {
    let isSubscribed = true;

    setTimeout(() => {
      if (isSubscribed) {
      }
    }, 1000);
    return () => (isSubscribed = false);
  });

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      if (Object.entries(timevalues).length !== 0) {
        // checkTime();
        let now = new Date();

        checkEnabled(
          new Date(new Date(timevalues.cutoff_datetime_utc).setSeconds(0)),
          now,
          timevalues.is_paying_status,
          timevalues.post_status
        );

        const interval = setInterval(() => {
          now = new Date();
          //formatDate();

          checkEnabled(
            new Date(new Date(timevalues.cutoff_datetime_utc).setSeconds(0)),
            now,
            timevalues.is_paying_status,
            timevalues.post_status
          );
        }, 1000);
        return () => clearInterval(interval);
      }
    }
    return () => (isSubscribed = false);
  }, [timevalues, checkEnabled]);

  /**
  const acceptingFor = (day) => {
    // Python's days start on Monday, using Python days not JS
    switch (parseInt(day)) {
      case 6:
        return "Sunday";
      case 0:
        return "Monday";
      case 1:
        return "Tuesday";
      case 2:
        return "Wednesday";
      case 3:
        return "Thursday";
      case 4:
        return "Friday";
      case 5:
        return "Saturday";
      default:
        return "";
    }
  };

  const handleChange = (event) => {
    const location = event.target.value
    const militaryTime = timevalues.tz_object[location]['time'].replace(/^0(?=[123456789])/, "").replace(/:00$/, "");
    const standardTime = toStandardTime(militaryTime);
    setTime(standardTime);
    setDate(timevalues.tz_object[location]['date'])
    setUtcOffset(timevalues.tz_object[location]['utc_offset'])
    setWeekday(timevalues.tz_object[location]['weekday'])
    const militaryStarttime = timevalues.tz_object[location]['start_time'].replace(/^0(?=[123456789])/, "").replace(/:00$/, "");
    const standardStarttime = toStandardTime(militaryStarttime)
    setStarttime(standardStarttime)
  };*/

  const toStandardTime = (time) => {
    const hour = parseInt(time.split(":")[0]);
    const minute = parseInt(time.split(":")[1]);
    var standardHour = hour%12;
    var ampm = 'pm';
    if (hour < 12){
      ampm = 'am';
    }
    if (standardHour === 0){
      standardHour = '00'
    }
    else {
      standardHour = String(standardHour);
    }
    return standardHour + ':' + minute + ampm;
  }
  

  return (
    <>
      <div >
        <h4 style={{color:"red"}}>New Pod Schedule Update!</h4>
        <div>Thanks to your feedback, we've updated the pod schedule to better suit your preferences. The new hours will take effect on <b>3rd February</b>.
        Scroll down to check the updated timetable! </div>
      </div>
    </>
  );
};

export default CutoffTimeComponent;
