export const getUTMParameters = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return {
        utm_source: urlParams.get("utm_source") || null,
        utm_medium: urlParams.get("utm_medium") || null,
        utm_campaign: urlParams.get("utm_campaign") || null,
        utm_content: urlParams.get("utm_content") || null,
        utm_term: urlParams.get("utm_term") || null,
    };
};
