import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import styles from './Onboarding.module.css';
import Topbar from './Topbar';

mixpanel.init("fce1521d323a955b4738b2588097761a", { debug: true, persistence: 'localStorage', ignore_dnt: true, });

function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

const NurtureToImproveConversion = () => {
    const [pageOption, setPageOption] = useState("https://www.linkedin.com/feed/");
    const [eventOption, setEventOption] = useState("LinkedIn");
    const [CTA, setCTA] = useState("Get Started on LinkedIn");
    const trackEvent1 = () => {
        mixpanel.track(
            "Onboarding",
            {
                "origin": "Nurture To Improve Conversion",
                "navigate": eventOption
            }
        );
    }
    const trackEvent2 = () => {
        mixpanel.track(
            "Onboarding",
            {
                "origin": "Nurture To Improve Conversion",
                "navigate": "Help & Strategy Hub"
            }
        );
    }
    useEffect(() => {
        const cookie = getCookie("onboarding_page_option");
        if (cookie === "ecosystem") {
            setPageOption("/Onboarding/EngageAI/WhyEngageAI");
            setEventOption("Why Jason Built EAI");
            setCTA("Next");
        }
      }, []);

    return (
        <div >
        <div >
            <Topbar/>
            <div className={`${styles['main']} ${styles['center-align']}`}>
                <h3 style={{color:"#2F3EAC"}}>Nurture To Improve Conversion</h3>
                <p><b>– increase touchpoints with every engagement.</b></p>
                <div className={`${styles['frow']} `} style={{justifyContent:"normal"}}>
                    <div style={{width:"100%"}}><iframe width="900" height="500" src="https://www.youtube.com/embed/ynFUcqxsR5U?si=B4XQHbokjhwdTx2k" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></div>
                </div>
                <div className={`${styles['fcolumn']} `}>
                    <div style={{width:"max-content", padding:"12px 16px", color:"white", background:"#3947A8", borderRadius:"8px", margin:"auto"}} >
                        <a href={pageOption} style={{color:"white", textDecoration:"none"}} onClick={trackEvent1}>{CTA} <img src='/images/right_arrow_white.svg' alt=''></img></a>
                    </div>
                    <div style={{color:"#3947A8", fontSize:"16px", fontWeight:"500", textAlign:"center", marginTop:"15px"}}>
                        <a href="https://engageai.atlassian.net/wiki/spaces/HelpStrategyHub/pages/1081745/Checklist%3A+Getting+Started" style={{textDecoration:"underline"}} onClick={trackEvent2}>Help & Strategy Hub</a>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}
export default NurtureToImproveConversion;