import mixpanel from "mixpanel-browser";
import { getUserProperties } from "./storageUtils";
import { getUTMParameters } from "./utmUtils";

mixpanel.init("fce1521d323a955b4738b2588097761a", {record_sessions_percent: 100, debug: true, persistence: 'localStorage', ignore_dnt: true,  });
export const registerMixpanelSuperProperties = ({ email, currentPlan, screenName }) => {
  const utmParams = getUTMParameters();
  mixpanel.register({
    '$user_id': email,
    'current_plan': currentPlan,
    'user_channel': 'web',
    'screen_name': screenName || "unknown",  
    ...utmParams,
  });
  mixpanel.people.set({
    '$email': email || null, 
    'current_plan': currentPlan || null, 
    'screen_name': screenName || "unknown", 
    'user_channel': 'chrome extension', 
    ...utmParams,
  });
};

export const mixpanelCTAEvent = ({ buttonName, ctaPosition, screenName }) => {
  if (!buttonName || !ctaPosition) {
    console.error("Mixpanel tracking requires buttonName and ctaPosition");
    return;
  }

  getUserProperties().then(({ email, currentPlan }) => {
    if (email) {
      mixpanel.identify(email); 
    }

    registerMixpanelSuperProperties({ email, currentPlan, screenName });
    const properties = {
      button_name: buttonName,
      cta_position: ctaPosition,
      screen_name: screenName || "unknown",  
    };
    mixpanel.track("cta_clicked", properties);
  }).catch((error) => {
    console.error("Error fetching user properties:", error);
  });
};


export const mixpanelEvent = (eventName, properties = {}, ) => {
  if (!eventName) {
    console.error("Mixpanel event tracking requires an event name");
    return;
  }

  getUserProperties().then(({ email, currentPlan }) => {
    if (email) {
      mixpanel.identify(email); 
    }
    registerMixpanelSuperProperties({ email, currentPlan, screenName: properties?.screen_name });

    mixpanel.track(eventName, properties);
  }).catch((error) => {
    console.error("Error fetching user properties:", error);
  });
};



