export const commonStyles = {
  formGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    flex: 1,

    "& label": {
      fontSize: "12px",
      fontWeight: "500",
      color: "rgba(65, 80, 88, 1)",
    },
  },
  passwordCriteria: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginTop: "6px",

    "& > span": {
      fontSize: "12px",
      fontWeight: "400",
      color: "rgba(65, 80, 88, 1)",
    },
    "& > ul": {
      display: "flex",
      flexDirection: "column",
      gap: "6px",
      margin: "0",
      paddingLeft: "4px",
      "& li": {
        fontSize: "12px",
        fontWeight: "500",
        color: "rgba(214, 31, 31, 1)",
        "&:nth-child(4)": { color: "rgba(65, 80, 88, 1)" },
      },
    },
  },
  errMsg: {
    fontSize: "14px",
    fontWeight: "500",
    color: "var(--color-red)",
    marginBottom: "0",
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",

    "& button": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "rgba(10, 114, 195, 1)",
      transition: "all .3s",
      height: "48px",
      "&:hover": { background: "#085b9c" },
      "&:disabled": { background: "#84b8e1", borderColor: "#84b8e1" },
    },

    "& span": {
      fontSize: "16px",
      fontWeight: "400",
      color: "rgba(65, 80, 88, 1)",
    },

    "& a": {
      fontSize: "16px",
      fontWeight: "600",
      color: "rgba(10, 114, 195, 1)",
      textDecoration: "none",
      cursor: "pointer",
    },
  },
  globalError: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    background: "rgba(251, 233, 233, 1)",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid rgba(214, 31, 31, 1)",
    "& svg": {
      color: "rgba(214, 31, 31, 1)",
      width: "24px",
      height: "24px",
    },
    "& span": {
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "17px",
      color: "rgba(214, 31, 31, 1)",
    },
  },
};
