import React, { useEffect, useContext, useState, useCallback } from "react";
import { Context } from "../../Store/Store";
import { useNavigate } from "react-router-dom";
// import Spinner from "../Spinner/Spinner";
import styles from "./AccountPage.module.css";
import { TransformUserData } from "../../Services/UserDataService";
import StripePortalSessionService from "../../Services/StripeService";
//import GetDailyStatsService from "../../Services/GetDailyStats";
import { UpdateUserData } from "../../Services/UserDataService";
import { mixpanelEvent } from "../../Utils/mixpanelUtils";

const BillingPage = () => {
  const [state, dispatch] = useContext(Context);
  const [group, setGroup] = useState();
  const [podAccess, setPodAccess] = useState(false);
  let history = useNavigate();
  const redirect = useCallback(
    (path) => {
      history(path);
    },
    [history]
  );

  //Call endpoint which calls the Stripe API to get a temporary URL to the user's Stripe portal
  const getStripePortalUrl = () => {
    StripePortalSessionService().then((res) => {
      if (res.status === 200) {
        res.json().then((json) => {
          if (json.data.url) {
            window.location.replace(json.data.url);
          } else {
            console.log("You are not a paying member, carry on posting");
            // TODO display message to user. Can edit message to sound less bad
          }
        });
      } else {
        console.error(res);
        // TODO display message to user
      }
    });
  };

  const handleSelect = (e) => {
    const plan = e.target.id;
    const t = localStorage.getItem("user");
    let u = JSON.parse(t);
    console.log(plan);
    if (plan === "Pro") {
      mixpanelEvent("plan_selected", {
        plan_name: "Pro",
        plan_price: "$30",
        plan_duration: "monthly",
        screen_name: "Billing Page",
      });
      redirect("/Upgrade");
    } else if (plan === "Executive") {
      redirect("/Executive");
    } else if (plan === "Premium") {
      mixpanelEvent("plan_selected", {
        plan_name: "Premium",
        plan_price: "$90",
        plan_duration: "monthly",
        screen_name: "Billing Page",
      });
      redirect("/Premium");
    } else if (plan === "Starter") {
      mixpanelEvent("plan_selected", {
        plan_name: "Starter",
        plan_price: "$10",
        plan_duration: "monthly",
        screen_name: "Billing Page",
      });
      redirect("/Starter");
    }else if (plan === "save-api-key") {
      const apiKey = document.getElementById("api-key").value;
      if (!apiKey) {
        document.getElementById("api-key").style.color = "red";
        document.getElementById("api-key").value = "Please enter a valid API key";
        return;
      } else if (!apiKey.includes("sk-")) {
        document.getElementById("api-key").style.color = "red";
        document.getElementById("api-key").value = "Invalid API key";
        return;
      }
      UpdateUserData({ group: "OpenAI_API", open_ai_api_key: apiKey }, null).then((res) => {
        if (res.status === 200) {
          e.target.innerHTML = "Success!";
          u.group = "OpenAI_API";
          u.open_ai_api_key = apiKey;
          dispatch({
            type: "SET_USER",
            payload: {
              data: TransformUserData(u),
            },
          });
        }
      });
    } else if (plan === "OpenAI_API") {
      setGroup("OpenAI_API");
    } else if (plan === "ChatGPT") {
      UpdateUserData({ group: "ChatGPT" }, null).then((res) => {
        if (res.status === 200) {
          e.target.innerHTML = "Success!";
          u.group = "ChatGPT";
          dispatch({
            type: "SET_USER",
            payload: {
              data: TransformUserData(u),
            },
          });
        }
      });
    } else if (plan === "ChatGPT_login") {
      window.open("https://chat.openai.com/auth/login");
    }
  };

  useEffect(() => {
    setGroup(state.user?.data?.group);
    setPodAccess(state.user?.data?.podAccess)
  }, [state]);

  return (
    <div>
      <div
        className="row"
        style={{
          flexWrap: "nowrap",
          padding: "1.5rem 2.5rem",
          flexDirection: "column",
          background: "var(--color-grey-50)",
        }}
      >
        <div style={{ flex: "1%" }}>
          {/* {loading ? <Spinner className="spinner-container" /> : ""} */}
          <div>
            <div style={{ display: "flex", gap: "10px", color: "#2F3EAC", textAlign: "center" }}>
              {/** Pod Access */}
              {podAccess ? 
                  <div
                  style={{ borderRadius: "4px", padding: "12px 16px", flexBasis: "100%" }}
                  className={`${
                    group === "Engage AI" ? styles["selected-plan"] : styles["unselected-plan"]
                  }`}
                >
                  <div style={{ fontSize: "20px", fontWeight: "700" }}>Pod Community</div>
                  <div style={{ margin: "10px 0" }}>$0/month</div>
                  <div style={{ height: "300px" }}>
                    <ul style={{ textAlign: "left", listStyleType: "disc" }}>
                      <li style={{ marginBottom: "20px" }}>LinkedIn engagement community and software</li>
                    </ul>
                  </div>
                  <div className={`${styles["button"]}`} onClick={getStripePortalUrl}>
                    Stripe Portal
                  </div>
                </div> : ''}
              {/** Free Plan */}
              {!podAccess ? <div
                style={{ borderRadius: "4px", padding: "12px 16px", flexBasis: "100%" }}
                className={`${
                  (group === "Engage AI" )|| group === "Chrome Extension"  ? styles["selected-plan"] : styles["unselected-plan"]
                }`}
              >
                <div style={{ fontSize: "20px", fontWeight: "700" }}>Free Plan</div>
                <div style={{ margin: "10px 0" }}>$0/month</div>
                <div style={{ height: "300px" }}>
                  <ul style={{ textAlign: "left", listStyleType: "disc" }}>
                    <li style={{ marginBottom: "20px" }}>OpenAI GPT-3.5</li>
                    <li style={{ marginBottom: "20px" }}>Unlimited comments</li>
                    <li style={{ marginBottom: "20px" }}>4 custom tones</li>
                    <li style={{ marginBottom: "20px" }}>10 prospects monitoring</li>
                    <li style={{ marginBottom: "20px" }}>Weekly monitoring update</li>
                    <li style={{ marginBottom: "20px" }}>Facebook community support</li>
                  </ul>
                </div>
                <div>
                  <a
                    href="https://engage-ai.co/pricing/"
                    target="__blank"
                    style={{ textDecoration: "underline" }}
                    onClick={() => {
                      mixpanelEvent("section_expanded", {
                        is_readmore: true,
                        section_title: "Free Plan",
                        screen_name: "Billing Page",
                      });
                    }}  
                  >
                      Learn more
                    </a>
                  </div>
              </div> : ''}
              { /** Starter Plan */}
              <div
                style={{ borderRadius: "4px", padding: "12px 16px", flexBasis: "100%" }}
                className={`${
                  group === "Starter" ? styles["selected-plan"] : styles["unselected-plan"]
                }`}
              >
                <div style={{ fontSize: "20px", fontWeight: "700" }}>Starter Plan</div>
                <div style={{ margin: "10px 0" }}>$10/month</div>
                <div style={{ height: "300px" }}>
                  <ul style={{ textAlign: "left", listStyleType: "disc" }}>
                    <li style={{ marginBottom: "20px" }}>OpenAI GPT-4o</li>
                    <li style={{ marginBottom: "20px" }}>6 custom tones</li>
                    <li style={{ marginBottom: "20px" }}>30 prospects monitoring</li>
                    <li style={{ marginBottom: "20px" }}>Daily and realtime monitoring update</li>
                    <li style={{ marginBottom: "20px" }}>LinkedIn content augmentation</li>
                    <li style={{ marginBottom: "20px" }}>Priority support (within 48 hours)</li>
                    <li style={{ marginBottom: "20px" }}>Plus everything in Free Plan</li>
                  </ul>
                </div>
                {group === "Starter" ? (
                  <div className={`${styles["button"]}`} onClick={getStripePortalUrl}>
                    Stripe Portal
                  </div>
                ) : (
                  <div>
                      <a
                        href="https://engage-ai.co/pricing/"
                        target="__blank"
                        style={{ textDecoration: "underline" }}
                        onClick={() => {
                          mixpanelEvent("section_expanded", {
                            is_readmore: true,
                            section_title: "Starter Plan",
                            screen_name: "Billing Page",
                          });
                        }}  
                      >
                      Learn more
                    </a>
                    <div className={`${styles["select-button"]}`} onClick={handleSelect} id="Starter">
                      Select
                    </div>
                  </div>
                )}
              </div>
              { /** Pro Plan */}
              <div
                style={{ borderRadius: "4px", padding: "12px 16px", flexBasis: "100%" }}
                className={`${
                  group === "Growth Plan" || group === "Member" || group === "Trial" ? styles["selected-plan"] : styles["unselected-plan"]
                }`}
              >
                <div style={{ fontSize: "20px", fontWeight: "700" }}>Pro Plan</div>
                <div style={{ margin: "10px 0" }}>$30/month</div>
                <div style={{ height: "300px" }}>
                  <ul style={{ textAlign: "left", listStyleType: "disc" }}>
                    <li style={{ marginBottom: "20px" }}>OpenAI GPT-4o</li>
                    <li style={{ marginBottom: "20px" }}>12 custom tones</li>
                    <li style={{ marginBottom: "20px" }}>150 prospects monitoring</li>
                    <li style={{ marginBottom: "20px" }}>GenAI is embedded throughout LinkedIn</li>
                    <li style={{ marginBottom: "20px" }}>Priority support (within 24 hours)</li>
                    <li>Plus everything in Starter Plan</li>
                  </ul>
                </div>
                {group === "Growth Plan" || group === "Member" ? (
                  <div className={`${styles["button"]}`} onClick={getStripePortalUrl}>
                    Stripe Portal
                  </div>
                ) : (
                  <div>
                      <a
                        href="https://engage-ai.co/pricing/"
                        target="__blank"
                        style={{ textDecoration: "underline" }}
                        onClick={() => {
                          mixpanelEvent("section_expanded", {
                            is_readmore: true,
                            section_title: "Pro Plan",
                            screen_name: "Billing Page",
                          });
                        }}
                      >
                      Learn more
                    </a>
                    <div className={`${styles["select-button"]}`} onClick={handleSelect} id="Pro">
                      Select
                    </div>
                  </div>
                )}
              </div>
              { /** Premium Plan */}
              <div
                style={{ borderRadius: "4px", padding: "12px 16px", flexBasis: "100%" }}
                className={`${
                  group === "Premium" ? styles["selected-plan"] : styles["unselected-plan"]
                }`}
              >
                <div style={{ fontSize: "20px", fontWeight: "700" }}>Premium Plan</div>
                <div style={{ margin: "10px 0" }}>$90/month</div>
                <div style={{ height: "300px" }}>
                  <ul style={{ textAlign: "left", listStyleType: "disc" }}>
                    <li style={{ marginBottom: "20px" }}>Google Gemini</li>
                    <li style={{ marginBottom: "20px" }}>20 custom tones</li>
                    <li style={{ marginBottom: "20px" }}>1,500 prospects monitoring</li>
                    <li style={{ marginBottom: "20px" }}>Early access to beta features</li>
                    <li style={{ marginBottom: "20px" }}>Priority support (within 24 hours)</li>
                    <li>Plus everything in Pro Plan</li>
                  </ul>
                </div>
                {group === "Premium" ? (
                  <div className={`${styles["button"]}`} onClick={getStripePortalUrl}>
                    Stripe Portal
                  </div>
                ) : (
                  <div>
                      <a
                        href="https://engage-ai.co/pricing/"
                        target="__blank"
                        style={{ textDecoration: "underline" }}
                        onClick={() => {
                          mixpanelEvent("section_expanded", {
                            is_readmore: true,
                            section_title: "Premium Plan",
                            screen_name: "Billing Page",
                          });
                        }}
                      >
                      Learn more
                    </a>
                    <div className={`${styles["select-button"]}`} onClick={handleSelect} id="Premium">
                      Select
                    </div>
                  </div>
                )}
              </div>      
              
              {/**<div style={{borderRadius:"4px", padding:"12px 16px", flexBasis:"100%"}} className={`${group === "ChatGPT" ? styles["selected-plan"] : styles["unselected-plan"]}`}>
                  <div style={{fontSize:"20px", fontWeight:"700"}}>ChatGPT Hack</div>
                  <div style={{margin:"10px 0"}}>$0</div>
                  <div style={{height:"250px"}}>
                    <ul style={{textAlign:"left", listStyleType:"disc"}}>
                      <li style={{marginBottom:"20px"}}>Unlock unlimited comment generations by syncing your free ChatGPT account with Engage AI.</li>
                      <li>Speed and stability depends on ChatGPT's capacity</li>
                    </ul>
                  </div>
                  {group === "ChatGPT" ? 
                  <div>
                    <div>Current Plan</div>
                    <div className={`${styles["button"]}`} onClick={handleSelect} id="ChatGPT_login">Login</div>
                  </div>
                    : 
                  group === "Growth Plan" || group === "Member" ? 
                  <div>
                    <a href="https://engage-ai.co/pricing/" target="__blank" style={{textDecoration:"underline"}}>Learn more</a> 
                  </div> :
                  <div>
                    <a href="https://engage-ai.co/pricing/" target="__blank" style={{textDecoration:"underline"}}>Learn more</a> 
                    <div className={`${styles["select-button"]}`} onClick={handleSelect} id="ChatGPT">Select</div>
                  </div>
                }
                </div>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingPage;
