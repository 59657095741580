import StyledButton from "../../NurtureProspects/ReusableComponents/Button";

import { Box } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { mixpanelCTAEvent } from "../../../Utils/mixpanelUtils";

const styles = {
  text: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "16px",
    padding: "24px 24px 0 24px",

    "& h3": {
      fontSize: "24px",
      fontWeight: "500",
      color: "rgba(0, 0, 0, 0.7)",
      margin: "0",
    },

    "& p": {
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: "400",
      color: "rgba(0, 0, 0, 0.7)",

      "& a, & a:link, & a:visited, & a:hover, & a:active": {
        color: "rgba(10, 114, 195, 1)",
        textDecoration: "none",
        fontWeight: "600",
      },
    },
  },
  engageBtn: {
    fontSize: "16px",
    fontWeight: "600",
    border: "2px solid rgba(10, 114, 195, 1)",
    background: "rgba(10, 114, 195, 1)",
    padding: "16px 32px",
    boxShadow: "0px 2px 8px 0px rgba(10, 114, 195, 0.2)",
    height: "42px",
    transition: "all .3s",
    "&:hover": { background: "#085b9c", borderColor: "#085b9c" },
  },
};

function StartEngaging() {
  return (
    <Box sx={styles.text}>
      <h3>Let's start engaging 🚀</h3>
      <p>
        Engage with Linkedin posts or explore other{" "}
        <a href="https://apps.engage-ai.co/" target="_blank" rel="noreferrer">
          powerful features of Engage AI
        </a>
      </p>
      <StyledButton
        sx={styles.engageBtn}
        onClick={() => {
          mixpanelCTAEvent({
            buttonName: "Open LinkedIn",
            ctaPosition: "hero",
            screenName: "extension onboarding",
          });
          window.open("https://www.linkedin.com/") 
        }}
      >
        <span>Open LinkedIn</span>
        <OpenInNewIcon />
      </StyledButton>
    </Box>
  );
}

export default StartEngaging;
