import React, { useEffect, useState } from 'react';
import styles from './LinkSubmitPage.module.css';
import Spinner from '../Spinner/Spinner';
import { setLinkDataLocalStorage } from '../../Scripts/getSetLocalStorage';
import DeleteLinkService from '../../Services/DeleteLinkService';
import { deleteLinkDataLocalStorage, deleteGeneratedResponsesLocalStorage } from '../../Scripts/getSetLocalStorage';
import SubmitLinkService from '../../Services/SubmitLinkService';
import { ReactTinyLink } from 'react-tiny-link';
import { mixpanelCTAEvent } from "../../Utils/mixpanelUtils";

const submitCross = (
    <svg width='15' height='15' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M7 5V0H5V5H0V7H5V12H7V7H12V5H7Z' fill='white' />
    </svg>
  );
  
  const rightArrow = (
    <svg width='18' height='18' viewBox='0 0 18 18' xmlns="http://www.w3.org/2000/svg" fill="#385A9F">
      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clipRule="evenodd" />
    </svg>
  );
  
  const deleteIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" viewBox="0 0 16 16">
      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
      <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
    </svg>
  )


const LinkSubmitForm = ({isEnabled, podID, subgroup}) => {
    const [isFirstTimeSubmitting, setIsFirstTimeSubmitting] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [reminder, setReminder] = useState();
    const [message1, setMessage1] = useState();
    const [formValues, setFormValues] = useState();

    const handleChange = (event) => {
        setMessage1();
        let link = event.target.value.trim();
        try {
            if (link !== '' && /^((https?):\/\/)?([w|W]{3}\.)*[a-zA-Z0-9\-.]{3,}\.[a-zA-Z]{2,}.*/.test(link)) {
                setFormValues({link: link, dateSubmitted: new Date()});
                setReminder("Please click on the 'Submit link' button to confirm link submission.");
            }
            else {
                setMessage1("Invalid link.");
                setFormValues();
            }
        }
        catch(e){}
    };

    const handleClick = () => {
        setIsFirstTimeSubmitting(false);
        window.open('https://engage-ai.co/best-practice/');
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        mixpanelCTAEvent({
            buttonName: "Submit link",
            ctaPosition: "hero",
            screenName: "Engagment community and software",
        });
        try {
            setIsLoading(true);
            SubmitLinkService(formValues.link, podID, subgroup)
              .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    setReminder();
                    setIsSubmitted(true);
                    setIsLoading(false);
                    setLinkDataLocalStorage({ link: formValues.link, dateSubmitted: new Date() });
                    mixpanelCTAEvent({
                        buttonName: "Submit link",
                        ctaPosition: "hero",
                        screenName: "Engagment community and software",
                    });
                } else {
                    setMessage1("Error submitting your post. Please try again or contact hello@engage-ai.co for help.")
                }
            })
          } catch (error) {
            setIsLoading(false);
          }
    }

    const handleDelete = (e) => {
        try {
            const link = formValues.link;
            DeleteLinkService(link)
            .then((res) => {
                // console.log(res);
                if (res.status === 200) {
                    setReminder();
                    setMessage1();
                    setIsSubmitted(false);
                    setFormValues();
                    deleteLinkDataLocalStorage();
                    deleteGeneratedResponsesLocalStorage();
                };
                
            })
            .catch(() => {
                //document.getElementById('uploadMessage').innerHTML = "An error occured!";
            });
        } catch (error) {}
    }

    useEffect(()=>{
        const s = JSON.parse(localStorage.getItem('stats'));
        const l = JSON.parse(localStorage.getItem('link'));
        if (s) {
            setIsFirstTimeSubmitting(s.is_first_time_submitting);
        }
        if (l) {
            setIsSubmitted(true);
            setFormValues(l);
        }
    }, [])

    return (
        <div>
            {loading ? <Spinner className='spinner-container' /> : ''}
            <div className={`${styles['helper-text-style']}`}>
                <div className={`${styles['flex-row-reverse']}`}>
                <div className='row mr-4'>
                <div className='mr-2 d-flex align-content-center'>{rightArrow}</div>
                <div className=' d-flex align-content-center '><button className={`${styles['post-delete-icon']} ${styles['helper-text-style']} col-12 h5`} onClick={handleClick}>Best Practice</button></div>
                </div>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className={`${styles['input-container']} row`}>
                <div className={`d-flex col-lg-10`}>
                    <input
                    className={`${styles['input-submit']} form-control mb-3`}
                    placeholder={!isEnabled ? 'Link submission is now closed': isFirstTimeSubmitting? `Before posting your first link, please visit the Best Practice page by clicking the link above the submit button` : 'Enter Your Post Link Here' }
                    type='text'
                    id='thisisalinksubmission'
                    name='thisisalinksubmission'
                    onChange={handleChange}
                    value={formValues? formValues.link : ''}
                    required
                    disabled={isEnabled ? false : true}
                    autoComplete='off'
                    />
                </div>
                <div className='col-lg-2'>
                    <button className={`${styles['submit-button']}`}>
                    <span className='mr-4'>Submit link </span>
                    <span>{submitCross}</span>
                    </button>
                </div>
                </div>
            </form>
            <div className='ml-4 mt-4'>
                {reminder ? <div style={{color:"red"}}><b>{reminder}</b></div> : ''}
                {isSubmitted ? 
                    <div>
                        <div style={{color:"#2e59dd"}}><b>Link submitted.</b></div>
                        <div className={`${styles['row']}`}>
                            <div ><button className={`${styles['post-delete-icon']} col-12`} onClick={handleDelete}>{deleteIcon}</button></div>{' '}
                            <div style={{marginLeft: "10px"}}>Click here to delete your submitted link. Remember to resubmit a new link by the cutoff time to participate in the pod run.</div>
                        </div>
                    </div> : ''}
                {message1? <div>{message1}</div> : ''}
                {formValues ? (
                    <ReactTinyLink cardSize='small' showGraphic={true} maxLine={2} minLine={1} url={formValues.link} onError={() => {}} />
                    ) : ''}
            </div>
        </div>
    )
}

export default LinkSubmitForm