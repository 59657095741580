import domain from "./DomainService";

export default function refreshTokenService() {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
      body: JSON.stringify({ refresh: refreshToken }),
    };
  
    return fetch(domain() + "api-token-refresh/", options);
  }
  