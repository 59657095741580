export default function CheckUserPlanService(email) {
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    };
  
    return fetch("https://r5na2baes7pfct3qjuacqqsgea0quhbm.lambda-url.ap-southeast-2.on.aws/", options);
  }
  