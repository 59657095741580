import { Avatar, Box, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";

import StyledButton from "../../ReusableComponents/Button";
import { IoClose } from "react-icons/io5";
import { IoIosCheckmark } from "react-icons/io";
import { Button } from "@mui/material";
import { DialogTitle } from "@mui/material";
import { mixpanelEvent  } from "../../../../Utils/mixpanelUtils";

const styles = {
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "5px 24px",
    gap: "24px",
    "& *": {
      margin: " 0",
    },
    "& h3": {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "29px",
      color: "#1F292E",
    },
    "& p": {
      fontSize: "16px",
      fontWeight: "400",
      color: "#415058",
      lineHeight: "24px",
      "& span": {
        fontSize: "14px",
        fontWeight: "700",
        color: "#1F292E",
      },
    },
  },
  closeBtn: {
    background: "#ecedee",
    border: "none",
    padding: "8px",
    borderRadius: "3px",
    transition: "all 0.2s",
    height: "40px",
    width: "40px",
    minWidth: "40px",

    "&:hover": {
      background: " #ecedeecc",
    },

    "& svg ": {
      width: "24px",
      height: "24px",
      color: "#1f292e",
    },
  },
  profileImg: {
    width: "96px",
    height: "96px",
  },
  checkBtn: {
    position: "absolute",
    bottom: "-16px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "38px",
    height: "38px",
    background: "#fff",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "1",
  },
};

function SucessAddProspect({ open, onClose, prospect }) {
  return (
    <Dialog
      open={open}
      onClick={() => {
        mixpanelEvent("dialog_closed", {
          dialog_section: 'x_icon_click',
          screen_name: 'save prospects via web app for monitoring', 
        });
        onClose(); 
      }}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.08), 0px 0px 4px rgba(0, 0, 0, 0.04)",
          width: "380px",
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="flex-end" mb="8px">
          <Button sx={styles.closeBtn} onClick={onClose}>
            <IoClose />
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={styles.modal}>
          <Box sx={{ position: "relative" }} mt="32px">
            <Avatar sx={styles.profileImg} src={prospect.imageUrl} alt={`${prospect.firstName} ${prospect.lastName}`} />
            <span style={styles.checkBtn}>
              <IoIosCheckmark
                style={{
                  height: "32px",
                  width: "32px",
                  background: "var(--color-brand)",
                  color: "#fff",
                  borderRadius: "50%",
                }}
              />
            </span>
          </Box>
          <h3>Prospect added</h3>
          <Typography sx={{ textAlign: "center" }}>
            <strong>
              {prospect.firstName} {prospect.lastName}
            </strong>{" "}
            has been added to your <strong>{prospect.list}</strong> prospect list.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ width: "100%" }} display="flex" justifyContent="center" pb="16px">
          <StyledButton
            onClick={() => {
              mixpanelEvent("dialog_closed", {
                dialog_section: 'closs button',
                screen_name: 'save prospects via web app for monitoring', 
              });
              onClose(); 
            }}
          >
            <IoClose />
            <span>Close</span>
          </StyledButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default SucessAddProspect;
